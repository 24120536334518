<div class="create__content-wrapper">
    <div class="create__header-wrapper">
        <h1 class="text-primary" *ngIf="userCanUpdate || !roleId"> {{!roleId ?
            'Agregar Nuevo Rol' : 'Editar Rol'}} </h1>
        <h1 class="text-primary" *ngIf="!userCanUpdate && roleId"> Detalle del Rol </h1>
    </div>
    <div class="create__body-wrapper create__body-wrapper--block">
        <div class="create__form-wrapper" *ngIf="isRolesFormLoaded">
            <div class="create__form-box">
                <prt-form #formTemplate [fields]="rolesForm" (change)="createFormChanges($event)"
                    (isFormValid)="createFormISValid($event)" [validateIsRequired]="true"></prt-form>
            </div>
            <div class="table-external-wrapper table-external-wrapper--with-overflox-x">
                <div class="create__table-box">
                    <p-table [rows]="subjectsRows" [columns]="actionsColumns" [innerIcons]="tableIcons" [demo]="true"
                        [pagination]="permissionsTablePagination" [resultLabel]="translations['subjects']"
                        (changeCurrentPage)="handleChangePermissionPage($event)"
                        [disableCheckboxes]="checkRoleReadOnlyPermission()" [staticRowActions]="true">
                    </p-table>
                </div>
            </div>
        </div>
    </div>
    <div class="create__footer-wrapper" *ngIf="userCanUpdate || !roleId">
        <prt-button [isInverted]="true" type="solid" [callback]="createRole">
            Guardar
        </prt-button>
        <prt-button type="outline" [callback]="cancelCreation">
            Cancelar
        </prt-button>
    </div>
    <div class="create__footer-wrapper" *ngIf="!userCanUpdate && roleId">
        <prt-button [isInverted]="true" type="solid" [callback]="cancelCreation">
            Aceptar
        </prt-button>
    </div>
</div>
