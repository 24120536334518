import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ability } from '@casl/ability';
import { An507FormRefInterface, DropdownField, TextBoxField, TextBoxPhoneField } from '@protostech/protos-lib';
import { ExportDataConfig, HeaderLine } from '@protostech/protos-lib/lib/interfaces/export-data-config';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import pdfMake from 'pdfmake/build/pdfmake';
import { GLOBAL_CONFIG } from 'src/app/constants/global-config';
import { Client } from 'src/app/models/clients';
import { getError } from 'src/app/models/error';
import { getDefaultExportDataConfig } from 'src/app/models/export-config';
import { Quotation } from 'src/app/models/quotation';
import { Technician, TechnicianForm } from 'src/app/models/technician';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { CreatedPdfService } from 'src/app/services/pdf/created-pdf.service';
import { QuotationsService } from 'src/app/services/quotations/quotations.service';
import { TechniciansService } from 'src/app/services/technicians/technicians.service';
import validation from 'src/app/utils/validation';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-technicians-create',
  templateUrl: './technicians-create.component.html',
  styleUrls: ['./technicians-create.component.scss'],
})
export class TechniciansCreateComponent implements OnInit {
  @ViewChild('formTemplate', { static: false }) techniciansFormId: An507FormRefInterface | undefined;
  @ViewChild('searchFormTemplate', { static: false }) searchFormId: An507FormRefInterface | undefined;
  @ViewChild('filterFormTemplate', { static: false }) filterFormId: An507FormRefInterface | undefined;

  //Search
  searchForm: TextBoxField[] = [
    {
      controlType: 'textBox',
      key: 'query',
      type: 'text',
      value: '',
      label: '',
      required: false,
      width: 'lg',
      noMargin: true,
      showInnerIcon: true,
      icon: '../assets/icons/search.svg',
      position: 'center',
      isSingleRow: true,
      placeholder: 'Escriba su búsqueda',
    },
  ];

  searchQuery: any = {
    page: 1,
    limit: 10,
    userId: '',
  };

  userCanCreate = false;
  userCanUpdate = false;
  showAcceptButton = false;

  technicianForm: TextBoxField[] | DropdownField[] | TextBoxPhoneField[] = [
    {
      controlType: 'textBox',
      key: 'name',
      type: 'text',
      value: '',
      label: 'Nombre y Apellido',
      required: true,
      width: 'lg',
      maxLength: 100,
      minLength: 2,
      regex: validation.namePattern,
      validationErrorMessage: 'El nombre no debe contener números',
      showErrorsUx: true,
      placeholder: 'Ingrese nombre y apellido',
    },
    {
      controlType: 'textBox',
      key: 'address',
      type: 'text',
      value: '',
      label: 'Dirección',
      required: false,
      width: 'lg',
      maxLength: 100,
      validationErrorMessage: 'Dirección inválida',
      showErrorsUx: true,
      placeholder: 'Ingrese dirección (opcional)',
    },
    {
      controlType: 'textBox',
      key: 'dni',
      type: 'text',
      value: '',
      label: 'Identificación',
      required: false,
      width: 'lg',
      maxLength: 100,
      regex: validation.dniPattern,
      validationErrorMessage: 'Identificación inválida',
      showErrorsUx: true,
      placeholder: 'Ingrese identificación (opcional)',
    },
    {
      controlType: 'textBox',
      key: 'email',
      type: 'string',
      value: '',
      label: 'Correo electrónico',
      required: true,
      width: 'lg',
      maxLength: 50,
      regex: validation.emailPattern,
      validationErrorMessage: 'Correo electrónico inválido',
      showErrorsUx: true,
      placeholder: 'Ingrese correo electrónico',
    },
    {
      controlType: 'textBoxPhone',
      key: 'phone',
      type: 'string',
      value: '',
      label: 'Número de contacto',
      required: true,
      width: 'lg',
      //regex: validation.phonePattern,
      validationErrorMessage: 'Número de contacto incorrecto',
      showErrorsUx: true,
    },
    {
      controlType: 'textBox',
      key: 'company',
      type: 'string',
      value: '',
      label: 'Compañía',
      required: false,
      width: 'lg',
      validationErrorMessage: 'Compañía inválida',
      showErrorsUx: true,
      placeholder: 'Ingrese compañía (opcional)',
    },
    {
      controlType: 'textBox',
      key: 'password',
      type: 'password',
      value: '',
      label: 'Contraseña',
      required: false,
      width: 'lg',
      minLength: 6,
      maxLength: 30,
      validationErrorMessage: 'Contraseña debe contener más de 5 caracteres y menos de 31',
      showErrorsUx: true,
      // noMargin: true,
      placeholder: 'Ingrese contraseña',
    },
  ];

  isTechnicianFormValid = false;

  technicianFormData: Partial<TechnicianForm> = {
    email: '',
    password: '',
    name: '',
    phone: {
      dialCode: '',
      number: '',
      countryCode: '',
    },
  };

  isFormLoaded = false;
  technicianId: string | null = null;

  technicianValues: Partial<Technician> = {
    id: '',
    email: '',
    name: '',
    createdAt: new Date(),
    address: '',
    dni: '',
    phone: '',
    phoneCode: '',
    picUrl: '',
    company: '',
    updatedAt: new Date(),
  };

  //Tabs Menu
  tabMenuOptions = [
    {
      key: 'INFORMATION',
      label: 'Datos personales',
    },
    {
      key: 'CLIENTS',
      label: 'Clientes',
    },
    {
      key: 'QUOTATIONS',
      label: 'Cotizaciones',
    },
  ];

  showTabMenu = false;
  tabSelected = 'INFORMATION';

  //Clients Table

  clientsTableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };

  clientsTableActions: Record<string, unknown> = {
    // modifyAction: (row: Record<string, unknown>) => this.editClient(row['id'] as string),
    // deleteAction: (row: Record<string, unknown>) => this.deleteClient(row['id'] as string),
  };

  clientsTable: any[] = [];

  clientsTableColumns = [
    {
      prop: 'number',
      name: 'Cliente',
    },
    {
      prop: 'name',
      name: 'Nombre',
    },
    {
      prop: 'phone',
      name: 'Teléfono',
    },
    {
      prop: 'address',
      name: 'Dirección',
    },
    {
      prop: 'purchasesCount',
      name: 'Compras',
      columnAlign: 'center',
    },
  ];

  clientsTablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  isClientFetchingData = true;
  isExportingClient = false;

  //Quotations table

  quotationsTableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };

  quotationsTableActions: Record<string, unknown> = {
    // modifyAction: (row: Record<string, unknown>) => this.editQuotation(row['id'] as string),
    // deleteAction: (row: Record<string, unknown>) => this.deleteQuotation(row['id'] as string),
  };

  quotationsTable: any[] = [];

  quotationsTableColumns = [
    {
      prop: 'status',
      name: 'Estatus',
      maxWidth: 50,
    },
    {
      prop: 'number',
      name: 'Número',
      columnAlign: 'center',
      maxWidth: 80,
    },
    {
      prop: 'total',
      name: 'Monto',
      columnAlign: 'right',
      maxWidth: 120,
    },
    {
      prop: 'createdAt',
      name: 'Fecha',
      columnAlign: 'center',
      maxWidth: 120,
    },
    {
      prop: 'name',
      name: 'Nombre de cliente',
      minWidth: 250,
    },
    {
      prop: 'Client',
      name: 'Cliente',
      columnAlign: 'center',
      maxWidth: 150,
    },
    {
      prop: 'profits',
      name: 'Ganancias',
      columnAlign: 'right',
      maxWidth: 130,
    },
    {
      prop: 'note',
      name: 'Detalles',
    },
  ];

  quotationsTablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  isQuotationFetchingData = true;
  isExportingQuotations = false;
  temporarySelectedImg = '';

  statusTranslations: Record<string, string> = {
    CREATED: 'Borrador',
    ISSUED: 'Enviada',
    ACCEPTED: 'Aceptada',
    PAID: 'Pagada',
    CANCELED: 'Anulada',
  };

  filterLabel: any;
  clientExportDataConfig: ExportDataConfig | undefined;
  quotationExportDataConfig: ExportDataConfig | undefined;
  dateFormat = GLOBAL_CONFIG.dateFormat;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private ability: Ability,
    private createdPdfService: CreatedPdfService,
    private techinicianService: TechniciansService,
    private clientService: ClientsService,
    private quotationService: QuotationsService,
    private pdfSvc: CreatedPdfService,
    private currencyPipe: CurrencyPipe,
  ) {
    //
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(async params => {
      this.technicianId = params.get('technicianId');
      if (this.technicianId) {
        this.showTabMenu = true;
        this.searchQuery['userId'] = this.technicianId;

        this.technicianValues = await this.techinicianService.getTechnicianById(this.technicianId);

        //this.clientsTableActions['downloadAction'] = this.handleCreateClientReport;
        //this.quotationsTableActions['downloadAction'] = this.handleCreateQuotationReport;

        this.fillForm();

        const section = params.get('section');

        let sortBy = params.get('sortBy');
        const sortOrder = params.get('sortOrder');
        const page = Number(params.get('page'));

        if (sortBy === 'User') {
          sortBy = sortBy + '.name';
        } else if (sortBy === 'Client') {
          sortBy = sortBy + '.number';
        } else if (sortBy === 'number') {
          sortBy = sortBy + 'Str';
        }

        this.buildQuery(sortBy, sortOrder, page);

        if (section === 'CLIENTS') {
          this.clientsTablePagination['page'] = page || 1;
          this.searchQuery['sortKey'] = sortBy || 'name';
          this.searchQuery['sortOrder'] = sortOrder || 'asc';
          this.fillClientsTable(sortBy, sortOrder);
          this.setClientExportationDataConfig();
          this.tabSelected = 'CLIENTS';
        } else if (section === 'QUOTATIONS') {
          this.quotationsTablePagination['page'] = page || 1;
          this.searchQuery['sortKey'] = sortBy || 'number';
          this.searchQuery['sortOrder'] = sortOrder || 'asc';
          this.fillQuotationsTable(sortBy, sortOrder);
          this.setQuotationExportationDataConfig();
          this.tabSelected = 'QUOTATIONS';
        }
      } else {
        this.technicianForm[6].required = true;
        this.isFormLoaded = true;
        this.showTabMenu = false;
      }
    });
  }

  ngAfterViewInit() {
    this.searchForm[0].triggerOnEnter = this.searchOnEnter;
    this.searchForm[0].triggerOnBlur = this.searchOnBlur;
  }

  async fillForm() {
    this.isFormLoaded = false;

    setTimeout(() => {
      this.techniciansFormId?.updateForm({
        email: this.technicianValues.email,
        password: '',
        name: this.technicianValues.name,
        address: this.technicianValues.address,
        dni: this.technicianValues.dni,
        phone: {
          dialCode: this.technicianValues.phoneCode,
          number: this.technicianValues.phone,
          countryCode: this.technicianValues.countryCode,
        },
        company: this.technicianValues.company,
      });
      this.isFormLoaded = true;
      this.canUpdate();
    });
  }

  //Permissions

  canCreate() {
    this.userCanCreate = this.ability.can('create', 'Technician');
  }

  canUpdate() {
    if (this.ability.cannot('update', 'Technician')) {
      this.techniciansFormId?.form.disable();
      this.userCanUpdate = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdate = true;
      this.showAcceptButton = false;
    }
  }

  //Create Technician
  createTechnician = () => {
    if (this.technicianValues?.id && this.isTechnicianFormValid) {
      const formData = {
        name: this.technicianFormData.name,
        email: this.technicianFormData.email,
        id: this.technicianValues.id,
        address: this.technicianFormData.address,
        company: this.technicianFormData.company,
        dni: this.technicianFormData.dni,
        phoneCode: this.technicianFormData.phone?.dialCode,
        phone: this.technicianFormData.phone?.number,
        countryCode: this.technicianFormData.phone?.countryCode,
        pic: this.technicianFormData.pic,
        password: this.technicianFormData.password,
      };
      if (formData.password === '') {
        delete formData.password;
      }
      this.techinicianService
        .updateTechnician(formData)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Técnico editado');
          this.router.navigate(['/private/technicians']);
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code, false, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    } else if (!this.technicianValues?.id && this.isTechnicianFormValid) {
      const formData = {
        ...this.technicianFormData,
        phone: this.technicianFormData.phone?.number,
        phoneCode: this.technicianFormData.phone?.dialCode,
        countryCode: this.technicianFormData.phone?.countryCode,
      };
      this.techinicianService
        .createTechnician(formData)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Técnico creado');
          this.router.navigate(['/private/technicians']);
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code, false, true);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    } else {
      this.checkDuplicateErrorMessage('Por favor, complete los campos obligatorios:', '', true, false);
      this.technicianForm.forEach((item: any) => {
        if (item.required) {
          this.techniciansFormId?.form.controls[item.key].markAsDirty();
        }
      });
    }
  };

  createTechnicianFormChanges(evt: object) {
    if (!(evt instanceof Event)) {
      Object.assign(this.technicianFormData, { ...(evt as TechnicianForm) });
    }
  }

  createTechnicianFormISValid(evt: boolean) {
    this.isTechnicianFormValid = evt;
  }

  cancelCreation = () => {
    this.router.navigate(['/private/technicians']);
  };

  //Error Messages
  checkDuplicateErrorMessage(title: string, message: string, getFormErrors?: boolean, getBackendErrors?: boolean) {
    let duplicate: any;
    if (getFormErrors) {
      const errorMessage = this.getFormErrors();
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else if (getBackendErrors) {
      const errorMessage = getError(message);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else {
      duplicate = this.toastr.findDuplicate(title, message, true, false);
    }
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  getFormErrors(extraFields?: string[]) {
    const error: string[] = [];
    this.technicianForm.forEach(element => {
      const isInvalid = (this.techniciansFormId as any).getFormValidationErrorsByKey(element.key);
      if (isInvalid.length > 0) {
        error.push(element.label);
      }
    });
    if (extraFields) {
      extraFields.forEach(e => {
        error.push(e);
      });
    }
    return error.join(', ');
  }

  //Search Products
  buildQuery(sortKey?: string | null, sortOrder?: string | null, page?: number) {
    if (page) {
      Object.assign(this.searchQuery, page);
    }
    if (sortKey) {
      Object.assign(this.searchQuery, { sortKey, sortOrder });
    }
  }

  searchOnEnter = (evt: any) => {
    if (evt.key === 'Enter') {
      const value = evt.target.value;
      this.updateSearchForm(value);
    }
  };

  searchOnBlur = (evt: any) => {
    this.updateSearchForm(evt.target.value);
  };

  updateSearchForm = (value: string) => {
    this.searchFormId?.form.setValue({ [this.searchForm[0].key]: value }, { emitEvent: true });
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsDirty();
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsTouched();
    this.search();
  };

  search = async () => {
    if (this.tabSelected === 'CLIENTS') {
      this.isClientFetchingData = true;
      if (!this.searchQuery['q']) {
        delete this.searchQuery['q'];
      }
      this.isClientFetchingData = true;
      return new Promise(async (res, rej) => {
        try {
          const clients = await this.clientService.getAllClients(this.searchQuery);
          this.clientsTable = clients.data.map(client => this.getClientRow(client));

          this.clientsTablePagination = { page: clients.page, limit: clients.limit, count: clients.count };
          res(true);
        } catch (error) {
          rej(error);
        }
      }).finally(() => {
        this.isClientFetchingData = false;
      });
    } else {
      this.isQuotationFetchingData = true;
      if (!this.searchQuery['q']) {
        delete this.searchQuery['q'];
      }
      this.isQuotationFetchingData = true;
      return new Promise(async (res, rej) => {
        try {
          const quotations = await this.quotationService.getAllQuotations(this.searchQuery);

          this.quotationsTable = quotations.data.map(quotation => this.getQuotationRow(quotation));

          this.quotationsTablePagination = { page: quotations.page, limit: quotations.limit, count: quotations.count };
          res(true);
        } catch (error) {
          rej(error);
        }
      }).finally(() => {
        this.isQuotationFetchingData = false;
      });
    }
  };

  getQuotationRow(quotation: Quotation, forReport = false) {
    const profits = this.currencyPipe.transform(`${quotation.profits}`);
    const total = this.currencyPipe.transform(`${quotation.total}`);
    const row: any = {
      id: quotation.id,
      status: this.translateStatus(quotation.status),
      total: total,
      number: quotation.numberStr,
      createdAt: moment.utc(quotation.createdAt).format(this.dateFormat),
      Client: quotation.Client?.number ?? '-',
      profits: profits,
      note: quotation.note && quotation.note !== '' ? quotation.note : '-',
      name: quotation.Client?.name ?? '-',
    };
    if (forReport) {
      delete row.id;
    }
    return row;
  }

  async searchFormChanges(evt: { query: string } | unknown) {
    if ((evt as { query: string }).query) {
      this.searchQuery = {
        ...this.searchQuery,
        ['q']: (evt as { query: string }).query,
      };
    } else {
      this.resetSearchQuery();
    }
  }

  resetSearchQuery() {
    delete this.searchQuery['q'];
    this.searchQuery = {
      ...this.searchQuery,
      userId: this.technicianId,
      page: 1,
      limit: 10,
    };
  }

  resetSort() {
    delete this.searchQuery['sortKey'];
    delete this.searchQuery['sortOrder'];
    this.router.navigate(['/private/technicians/edit'], { queryParams: { technicianId: this.technicianId } });
  }

  resetTablePagination() {
    this.clientsTablePagination['page'] = 1;
    this.quotationsTablePagination['page'] = 1;
  }

  cleanSearch(key: string) {
    this.searchFormId?.resetForm();
    this.resetSearchQuery();
    this.resetSort();
    this.resetTablePagination();
    if (key === 'CLIENTS') {
      this.fillClientsTable();
      this.router.navigate(['/private/technicians/edit'], {
        queryParams: { section: 'CLIENTS', sortBy: null, sortOrder: null },
        queryParamsHandling: 'merge',
      });
    } else {
      this.fillQuotationsTable();
      this.router.navigate(['/private/technicians/edit'], {
        queryParams: { section: 'QUOTATIONS', sortBy: null, sortOrder: null },
        queryParamsHandling: 'merge',
      });
    }
  }

  //Clients View
  //Clients Report
  handleCreateClientReport = async () => {
    this.isExportingClient = true;
    const clients = await this.clientService.getAllClients(this.searchQuery);

    const clientReport = clients.data.map(client => {
      return {
        number: client.numberStr,
        name: client.name,
        phoneCode: client.phoneCode,
        phone: client.phone,
        address: client.address,
        purchasesCount: client.purchasesCount,
        User: client.User.name,
      };
    });
    const pdfDefinition: any = this.pdfSvc.createdReportClients(clientReport);
    pdfMake.createPdf(pdfDefinition).download('Clientes.pdf');
    // pdfMake.createPdf(pdfDefinition).open();
    this.isExportingClient = false;
  };

  //Clients Table
  async fillClientsTable(sortKey?: string | null, sortOrder?: string | null) {
    this.isClientFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const clients = await this.clientService.getAllClients({
          userId: this.technicianId,
          page: this.clientsTablePagination.page,
          limit: this.clientsTablePagination.limit,
          sortKey: sortKey ? sortKey : 'name',
          sortOrder: sortOrder ? sortOrder : 'asc',
        });

        this.clientsTable = clients.data.map(client => this.getClientRow(client));

        this.clientsTablePagination = { page: clients.page, limit: clients.limit, count: clients.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isClientFetchingData = false;
    });
  }

  private getClientRow(client: Client, forReport = false) {
    const row: any = {
      id: client.id,
      number: client.numberStr,
      name: client.name,
      phoneCode: client.phoneCode,
      phone: client.phone ?? '-',
      address: client.address ?? '-',
      purchasesCount: `${client.purchasesCount}`,
      User: client.User.name,
    };
    if (forReport) {
      delete row.id;
    }
    return row;
  }

  handleClientsChangePage(page: number) {
    this.clientsTablePagination.page = page;
    this.router.navigate(['/private/technicians/edit'], { queryParams: { page }, queryParamsHandling: 'merge' });
    if (this.searchQuery['q']) {
      this.search();
    }
  }

  onSortClientsTable(evt: { dir: string; column: string }) {
    this.clientsTablePagination.page = 1;
    this.router
      .navigate(['/private/technicians/edit'], {
        queryParams: { sortBy: evt.column, sortOrder: evt.dir, page: 1 },
        queryParamsHandling: 'merge',
      })
      .then(() => this.setClientExportationDataConfig());
  }

  //Quotations
  //Quotations Report
  handleCreateQuotationReport = async () => {
    this.isExportingQuotations = true;
    const quotations = await this.quotationService.getAllQuotations(this.searchQuery);

    const quotationReport = quotations.data.map(quotation => {
      return {
        status: this.translateStatus(quotation.status),
        total: quotation.total,
        number: quotation.numberStr,
        createdAt: moment.utc(quotation.createdAt).format(this.dateFormat),
        Client: quotation.Client.number,
        profits: quotation.profits,
        note: quotation.note,
      };
    });
    const pdfDefinition: any = this.pdfSvc.createdReportQuotation(quotationReport, true);
    pdfMake.createPdf(pdfDefinition).download('Cotizaciones.pdf');
    // pdfMake.createPdf(pdfDefinition).open();
    this.isExportingQuotations = false;
  };

  //Quotations Table
  async fillQuotationsTable(sortKey?: string | null, sortOrder?: string | null) {
    this.isQuotationFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const quotations = await this.quotationService.getAllQuotations({
          userId: this.technicianId,
          page: this.quotationsTablePagination.page,
          limit: this.quotationsTablePagination.limit,
          sortKey: sortKey ? sortKey : 'number',
          sortOrder: sortOrder ? sortOrder : 'asc',
        });

        this.quotationsTable = quotations.data.map(quotation => this.getQuotationRow(quotation));

        this.quotationsTablePagination = { page: quotations.page, limit: quotations.limit, count: quotations.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isQuotationFetchingData = false;
    });
  }

  handleQuotationChangePage(page: number) {
    this.quotationsTablePagination.page = page;
    this.router.navigate(['/private/technicians/edit'], { queryParams: { page }, queryParamsHandling: 'merge' });
    if (this.searchQuery['q']) {
      this.search();
    }
  }

  onSortQuotationsTable(evt: { dir: string; column: string }) {
    this.quotationsTablePagination.page = 1;
    this.router
      .navigate(['/private/technicians/edit'], {
        queryParams: { sortBy: evt.column, sortOrder: evt.dir, page: 1 },
        queryParamsHandling: 'merge',
      })
      .then(() => this.setQuotationExportationDataConfig());
  }

  translateStatus(statusKey: string) {
    return this.statusTranslations[statusKey];
  }

  attachImg(img: any) {
    const reader = new FileReader();
    const file = img.target.files[0];
    reader.onload = e => {
      this.temporarySelectedImg = reader.result as string;
      this.technicianFormData.pic = img.target.files[0];
    };
    reader.readAsDataURL(file);
  }

  async handleChangedTab(key: string) {
    if (this.technicianId && key === 'INFORMATION') {
      this.technicianValues = await this.techinicianService.getTechnicianById(this.technicianId);
      this.techniciansFormId?.updateForm(this.technicianValues);
    }
    if (key !== 'INFORMATION') {
      this.cleanSearch(key);
    }
    if (key === 'CLIENTS') {
      this.fillClientsTable();
      this.setClientExportationDataConfig();
    }
    if (key === 'QUOTATIONS') {
      this.fillQuotationsTable();
      this.setQuotationExportationDataConfig();
    }
  }

  async setClientExportationDataConfig() {
    this.clientExportDataConfig = getDefaultExportDataConfig();
    this.clientExportDataConfig.data = await this.getClientReportData();
    this.clientExportDataConfig.fileName = 'Clientes';
    this.clientExportDataConfig.config.columns = this.getClientReportColumns();
    this.clientExportDataConfig.config.pdfConfigOptions = { pdfColumnWidth: [] };
    this.clientExportDataConfig.config.pdfConfigOptions.pdfColumnWidth = [40, 100, 80, 180, 60];
    this.clientExportDataConfig.config.pdfConfigOptions.pdfHeaderFontSize = 10;
    this.clientExportDataConfig.config.headerLines = this.getHeaderLines();
  }

  async setQuotationExportationDataConfig() {
    this.quotationExportDataConfig = getDefaultExportDataConfig();
    this.quotationExportDataConfig.data = await this.getQuotationReportData();
    this.quotationExportDataConfig.fileName = 'Cotizaciones';
    this.quotationExportDataConfig.config.columns = this.getQuotationReportColumns();
    this.quotationExportDataConfig.config.pdfConfigOptions = { pdfColumnWidth: [] };
    this.quotationExportDataConfig.config.pdfConfigOptions.pdfColumnWidth = [40, 40, 60, 50, 150, 35, 60, 230];
    this.quotationExportDataConfig.config.pdfConfigOptions.pdfHeaderFontSize = 10;
    this.quotationExportDataConfig.config.headerLines = this.getHeaderLines();
    this.quotationExportDataConfig.config.pageOritation = 'landscape';
  }

  private getHeaderLines() {
    return [
      {
        text: `Técnico: ${this.technicianValues.name}`,
        style: 'subheader',
        alignment: 'left',
      },
    ] as HeaderLine[];
  }

  async getClientReportData() {
    this.searchQuery['limit'] = 0;
    const clients = await this.clientService.getAllClients(this.searchQuery);
    const clientReport = clients.data.map(client => this.getClientRow(client, true));
    return clientReport;
  }

  async getQuotationReportData() {
    this.searchQuery['limit'] = 0;
    const clients = await this.quotationService.getAllQuotations(this.searchQuery);
    const clientReport = clients.data.map(quotation => this.getQuotationRow(quotation, true));
    return clientReport;
  }

  private getClientReportColumns() {
    const columns = this.clientsTableColumns
      .map(col => {
        const reportCol = {
          header: col.name,
          key: col.prop,
          width: 20,
          style: {
            alignment: {
              horizontal: 'left' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          },
        };
        if (reportCol.key === 'purchasesCount') {
          const propertyValue = {
            alignment: {
              horizontal: 'center' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          };
          Object.defineProperty(reportCol, 'style', {
            value: propertyValue,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        return reportCol;
      })
      .filter(col => col.key !== 'delete');
    return columns;
  }

  private getQuotationReportColumns() {
    const columns = this.quotationsTableColumns
      .map(col => {
        const reportCol = {
          header: col.name,
          key: col.prop,
          width: 20,
          style: {
            alignment: {
              horizontal: 'left' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          },
        };

        if (reportCol.key === 'total' || reportCol.key === 'profits') {
          const propertyValue = {
            alignment: {
              horizontal: 'right' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          };
          Object.defineProperty(reportCol, 'style', {
            value: propertyValue,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        if (reportCol.key === 'createdAt') {
          const propertyValue = {
            alignment: {
              horizontal: 'center' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          };
          Object.defineProperty(reportCol, 'style', {
            value: propertyValue,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        return reportCol;
      })
      .filter(col => col.key !== 'delete' && col.key !== 'extraAction2');
    return columns;
  }
}
