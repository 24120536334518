import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { labelsStep1, pipesDistanceUnitOptions, pipesGoTogetherOptions, placeholdersStep1 } from './step1.config';
import { Subject, takeUntil } from 'rxjs';
import { PrtOptionDropdownModel, PrtOptionRadioButtonModel } from '@protostech/protos-lib/v2';
import { ProductsService } from 'src/app/services/products/products.service';
import { Unit } from 'src/app/enums/unit';
import { PipeType, PipeTypeTranslation } from 'src/app/enums/pipe-type';

@Component({
  selector: 'app-step1',
  templateUrl: './step1.component.html',
  styleUrls: ['./step1.component.scss'],
})
export class Step1Component implements OnInit {
  labels = labelsStep1;
  placeholders = placeholdersStep1;
  destroy$ = new Subject<void>();
  pipesGoTogetherOptions: PrtOptionRadioButtonModel[] = pipesGoTogetherOptions;
  pipesDistanceUnitOptions: PrtOptionDropdownModel[] = pipesDistanceUnitOptions;
  pipeTypeOptions: PrtOptionDropdownModel[] = [];
  loading = false;

  constructor(public formGroup: FormGroup, private productsService: ProductsService) {
    //
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.subscribeToEvent();
    await this.fillDropdowns();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private subscribeToEvent() {
    this.onPipeTypeChange();
  }

  onPipeTypeChange() {
    const pipesType = this.formGroup.get('pipesType');
    pipesType?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value: string) => {
      this.changePipeUnitValue(value);
    });
  }

  private changePipeUnitValue(value: string) {
    const pipesDistanceUnit = this.formGroup.get('pipesDistanceUnit');
    if (value === PipeType.PEX) {
      pipesDistanceUnit?.setValue(Unit.METROS);
    } else if (value === PipeType.COPPER) {
      pipesDistanceUnit?.setValue(Unit.PIES);
    }
  }

  private async fillDropdowns(): Promise<void> {
    await this.fillPipeType();
  }

  private async fillPipeType() {
    const response = await this.productsService.getPipeTypes();
    this.pipeTypeOptions = response.map(pipesType => ({
      label: PipeTypeTranslation[pipesType as unknown as keyof typeof PipeTypeTranslation],
      value: pipesType,
    }));
  }
}
