<h3>Mano de Obra</h3>
<form *ngIf="formGroup" [formGroup]="formGroup">
    <prt-form-group>
        <prt-form-field size="lg">
            <prt-label>{{ labels.labor }}</prt-label>
            <prt-input appDefaultFocus type="number" formControlName="labor" [placeholder]="placeholders.labor" prtHostControl></prt-input>
        </prt-form-field>
    </prt-form-group>
</form>
<app-loading class="step-loading" [show]="loading"></app-loading>
