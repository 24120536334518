import { Validators } from '@angular/forms';

const brandLabel = 'Marca';
const modelLabel = 'Producto';

export const labelsStep0 = {
  buyFromUs: '¿El cliente desea comprar el equipo con nosotros?',
  airConditionerType: 'Tipo',
  brand: brandLabel,
  airConditionerModelId: modelLabel,
  airConditionerBTU: 'Capacidad (BTU)',
  brandName: brandLabel,
  modelName: modelLabel,
  price: 'Precio Compra Socio ($)',
  pipe1Diameter: 'Diámetro Tubería 1',
  pipe2Diameter: 'Diámetro Tubería 2',
};

export const placeholders = {
  capacity: 'BTU',
  brandName: 'Escriba marca',
  modelName: 'Escriba producto',
  price: '$',
  brand: 'Seleccione marca',
  airConditionerModelId: 'Seleccione producto',
  airConditionerBTU: 'Seleccione capacidad (BTU)',
  pipe1Diameter: 'Seleccione diámetro Tubería 1',
  pipe2Diameter: 'Seleccione diámetro Tubería 2',
};

export const step0Controls = {
  buyFromUs: ['true', Validators.required],
  airConditionerType: ['', Validators.required],
  brand: ['', Validators.required],
  airConditionerModelId: ['', [Validators.required]],
  airConditionerBTU: ['', [Validators.required]],
  price: [''],
  pipe1Diameter: [{ value: '', disabled: true }],
  pipe2Diameter: [{ value: '', disabled: true }],
  brandName: [''],
  modelName: [''],
};

export const buyFromUsOptions = [
  { value: 'true', label: 'Si' },
  { value: 'false', label: 'No' },
];
