import { Injectable } from '@angular/core';
import { Product } from 'src/app/models/products';

@Injectable({
  providedIn: 'root',
})
export class WizardService {
  airConditioner?: Product;

  constructor() {
    //
  }

  destroy() {
    this.airConditioner = undefined;
  }
}
