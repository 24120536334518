import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { buyFromUsOptions, labelsStep0, placeholders } from './step0.config';
import { Subject, takeUntil } from 'rxjs';
import { PrtOptionDropdownModel, PrtOptionRadioButtonModel } from '@protostech/protos-lib/v2';
import { MarksService } from 'src/app/services/marks/marks.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { Product } from 'src/app/models/products';
import { WizardService } from 'src/app/services/wizard/wizard.service';
import { FormUtilsService } from 'src/app/services/utils/form-utils.service';
import { WizardGeneralEventsService } from 'src/app/services/wizard-general-events/wizard-general-events.service';

interface ModelFilter {
  brand?: string;
  airConditionerType?: string;
  capacity?: string;
}
@Component({
  selector: 'app-step0',
  templateUrl: './step0.component.html',
  styleUrls: ['./step0.component.scss'],
})
export class Step0Component implements OnInit, OnDestroy {
  labels = labelsStep0;
  placeholders = placeholders;
  destroy$ = new Subject<void>();
  markOptions: PrtOptionDropdownModel[] = [];
  modelOptions: PrtOptionDropdownModel[] = [];
  pipeDiameter1Options: PrtOptionDropdownModel[] = [];
  pipeDiameter2Options: PrtOptionDropdownModel[] = [];
  airConditionerBTUOptions: PrtOptionDropdownModel[] = [];
  buyFromUsOptions: PrtOptionRadioButtonModel[] = buyFromUsOptions;
  airConditionerTypeOptions: PrtOptionDropdownModel[] = [];
  loading = false;
  showInputs = {
    diameter1: false,
    diameter2: false,
    price: false,
    brandName: false,
    modelName: false,
  };
  modelFilter: ModelFilter = {
    brand: undefined,
    airConditionerType: undefined,
    capacity: undefined,
  };

  constructor(
    private marksService: MarksService,
    private productsService: ProductsService,
    private wizardService: WizardService,
    private formUtilsService: FormUtilsService,
    private wizardGeneralEventsService: WizardGeneralEventsService,
    private formArray: FormArray,
    public formGroup: FormGroup,
  ) {
    //
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    await this.fillForm();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async fillForm() {
    this.subscribeToEvent();
    const brand = this.formGroup.get('brand');
    const buyFromUs = this.formGroup.get('buyFromUs');
    const airConditionerModelId = this.formGroup.get('airConditionerModelId');
    const airConditionerBTU = this.formGroup.get('airConditionerBTU');
    const airConditionerType = this.formGroup.get('airConditionerType');
    this.modelFilter = {
      brand: brand?.value,
      airConditionerType: airConditionerType?.value,
      capacity: airConditionerBTU?.value,
    };
    if (this.isModelFilterValid()) {
      this.fillProducts(this.modelFilter);
    }
    if (buyFromUs?.value === 'true') {
      this.fillMarks(airConditionerType?.value, airConditionerBTU?.value);
    }
    await this.fillAirConditionerTypes();
    await this.fillAirConditionerBTUOptions(airConditionerType?.value, brand?.value);
    await this.getPipeDiametersOptions();
    this.changePrice(buyFromUs?.value);
    this.changeBrandName(buyFromUs?.value);
    this.changeModelName(buyFromUs?.value);
    this.changeBrand(buyFromUs?.value);
    this.changeAirConditionerId(buyFromUs?.value);
    this.fillControls(airConditionerModelId?.value);
    this.enableAirConditionerModelId();
    this.enableBrand();
    this.enableAirConditionerBTU();
  }

  private onBrandChange() {
    const brand = this.formGroup.get('brand');
    brand?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async value => {
      if (value && value !== '') {
        const airConditionerTypeValue = this.formGroup.get('airConditionerType')?.value;
        this.modelFilter.brand = value;
        await this.fillAirConditionerBTUOptions(airConditionerTypeValue, value);
        await this.fillProducts(this.modelFilter);
      } else {
        this.modelFilter.brand = undefined;
      }
      this.enableAirConditionerModelId();
    });
  }

  private onModelChange() {
    const airConditionerModelId = this.formGroup.get('airConditionerModelId');
    airConditionerModelId?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async value => {
      this.fillControls(value);
    });
  }

  async fillControl(controlName: 'pipe1Diameter' | 'pipe2Diameter' | 'price', value?: string) {
    const control = this.formGroup.get(controlName);
    if (value && value !== '') {
      control?.enable();
      control?.setValue(value, { emittEvent: false });
      control?.disable();
    } else {
      control?.reset();
    }
  }

  /**
   * Fills the form controls with the product details if the "buyFromUs" control is set to 'true'
   * and a valid air conditioner model ID is provided.
   *
   * @param airConditionerModelId - The ID of the air conditioner model to fetch product details for.
   *
   * @remarks
   * This method works with the following form controls:
   * - 'price': The price of the product.
   * - 'pipe1Diameter': The diameter of the first pipe.
   * - 'pipe2Diameter': The diameter of the second pipe.
   *
   * @returns A promise that resolves when the controls are filled.
   */
  private async fillControls(airConditionerModelId: string) {
    const buyFromUs = this.formGroup.get('buyFromUs');
    if (buyFromUs?.value === 'true' && airConditionerModelId && airConditionerModelId !== '') {
      const product = await this.productsService.getProductById(airConditionerModelId);
      this.fillControl('price', `${product.price}`);
      this.fillControl('pipe1Diameter', product.diameter1 ?? '-');
      this.fillControl('pipe2Diameter', product.diameter2 ?? '-');
    } else {
      this.resetReadOnlyControls();
    }
  }

  private async getPipeDiametersOptions() {
    const product = this.wizardService.airConditioner;
    if (!product?.diameter1) {
      await this.fillPipeDiameters('pipeDiameter1Options');
    }
    if (!product?.diameter2) {
      await this.fillPipeDiameters('pipeDiameter2Options');
    }
  }

  private async fillPipeDiameters(option: 'pipeDiameter1Options' | 'pipeDiameter2Options') {
    const response = await this.productsService.getPipeDiameters();
    this[option] = response.map((diameter: string) => ({
      label: diameter,
      value: diameter,
    }));
  }

  private changePipeDiameters(value: string) {
    const pipe1Diameter = this.formGroup.get('pipe1Diameter');
    const pipe2Diameter = this.formGroup.get('pipe2Diameter');
    const isNotBuyFromUs = value === 'false';
    this.showInputs.diameter1 = isNotBuyFromUs && this.pipeDiameter1Options.length > 0;
    this.showInputs.diameter2 = isNotBuyFromUs && this.pipeDiameter1Options.length > 0;
    pipe1Diameter?.reset();
    pipe2Diameter?.reset();
    if (isNotBuyFromUs) {
      pipe1Diameter?.enable({ emitEvent: false });
      pipe2Diameter?.enable({ emitEvent: false });
    } else {
      pipe1Diameter?.disable({ emitEvent: false });
      pipe2Diameter?.disable({ emitEvent: false });
    }
    this.formUtilsService.updateValidators(pipe1Diameter, isNotBuyFromUs);
    this.formUtilsService.updateValidators(pipe2Diameter, isNotBuyFromUs);
  }

  private changePrice(value: string) {
    const price = this.formGroup.get('price');
    const isNotBuyFromUs = value === 'false';
    this.showInputs.price = !isNotBuyFromUs;
    if (isNotBuyFromUs) {
      price?.enable();
      price?.enable();
    } else {
      price?.disable();
      price?.disable();
    }
    if (!isNotBuyFromUs) {
      price?.reset();
    }
  }

  private changeBrandName(value: string) {
    const brandName = this.formGroup.get('brandName');
    const isNotBuyFromUs = value === 'false';
    this.showInputs.brandName = isNotBuyFromUs;
    if (!isNotBuyFromUs) {
      brandName?.reset();
    }
  }

  private changeModelName(value: string) {
    const modelName = this.formGroup.get('modelName');
    const isNotBuyFromUs = value === 'false';
    this.showInputs.modelName = isNotBuyFromUs;
    if (!isNotBuyFromUs) {
      modelName?.reset();
    }
  }

  private changeBrand(value: string) {
    const brand = this.formGroup.get('brand');
    const isBuyFromUs = value === 'true';
    this.formUtilsService.updateValidators(brand, isBuyFromUs);
    if (!isBuyFromUs) {
      brand?.reset();
    }
  }

  private changeAirConditionerId(value: string) {
    const airConditionerModelId = this.formGroup.get('airConditionerModelId');
    const isBuyFromUs = value === 'true';
    this.formUtilsService.updateValidators(airConditionerModelId, isBuyFromUs);
    if (!isBuyFromUs) {
      airConditionerModelId?.reset(null, { emitEvent: false });
    }
  }

  private async fillMarks(type: string, capacity?: string) {
    const query: any = { limit: 0, type };
    if (capacity) {
      query.capacity = capacity;
    }
    const response = (await this.marksService.getAll(query)) as any;
    const options = response.map((brand: string) => ({ value: brand, label: brand }));
    this.markOptions = options;
    this.formUtilsService.autoSelectSingleOption(this.formGroup.get('brand'), this.markOptions);
    if (this.markOptions.length === 1) {
      this.modelFilter.brand = this.markOptions[0].value as string;
    }
  }

  private async fillAirConditionerBTUOptions(type: string, brand?: string) {
    const query: any = { limit: 0, type };
    if (brand) {
      query.brand = brand;
    }
    const response = (await this.productsService.getAirConditionerBtus(query)) as any;
    const options = response.map((btu: string) => ({ value: btu, label: btu }));
    this.airConditionerBTUOptions = options;
    this.formUtilsService.autoSelectSingleOption(
      this.formGroup.get('airConditionerBTU'),
      this.airConditionerBTUOptions,
    );
    if (this.airConditionerBTUOptions.length === 1) {
      this.modelFilter.capacity = this.airConditionerBTUOptions[0].value as string;
    }
  }

  private async fillProducts(filter: ModelFilter) {
    if (this.isModelFilterValid()) {
      const query: any = { limit: 0, ...filter };
      const response = (await this.productsService.getAllProductModels(query)) as any;
      const options = response.data.map((product: Product) => ({ value: product.id, label: product.name }));
      this.modelOptions = options;
      this.formUtilsService.autoSelectSingleOption(
        this.formGroup.get('airConditionerModelId'),
        this.modelOptions,
        true,
      );
    } else {
      this.modelOptions = [];
    }
  }

  private isModelFilterValid(): boolean {
    return Object.values(this.modelFilter).every(value => value !== '' && value !== undefined);
  }

  private subscribeToEvent() {
    this.onBrandChange();
    this.onModelChange();
    this.onBuyFromUsChange();
    this.onAirConditionerBTUChange();
    this.onAirConditionerTypeChange();
    this.wizardGeneralEventsService.onAirConditionerBTUChange(this.formArray);
  }

  private enableAirConditionerModelId() {
    const airConditionerType = this.formGroup.get('airConditionerType')?.value;
    const brand = this.formGroup.get('brand')?.value;
    const airConditionerBTU = this.formGroup.get('airConditionerBTU')?.value;
    const airConditionerModelId = this.formGroup.get('airConditionerModelId');
    const isValid =
      airConditionerType &&
      airConditionerType !== '' &&
      brand &&
      brand !== '' &&
      airConditionerBTU &&
      airConditionerBTU !== '';
    this.formUtilsService.toggleControl(airConditionerModelId, isValid);
    if (!isValid) {
      airConditionerModelId?.reset(null, { emitEvent: false });
      this.resetReadOnlyControls();
    }
  }

  private enableBrand() {
    const buyFromUsValue = this.formGroup.get('buyFromUs')?.value;
    const airConditionerTypeValue = this.formGroup.get('airConditionerType')?.value;
    const brand = this.formGroup.get('brand');
    const isValid = airConditionerTypeValue && airConditionerTypeValue !== '';
    this.formUtilsService.toggleControl(brand, isValid);
    if (!isValid) {
      brand?.reset(null, { emitEvent: false });
      this.resetBrandCapacityModelControls();
    }
  }

  private enableAirConditionerBTU() {
    const buyFromUsValue = this.formGroup.get('buyFromUs')?.value;
    const airConditionerTypeValue = this.formGroup.get('airConditionerType')?.value;
    const airConditionerBTU = this.formGroup.get('airConditionerBTU');
    const isValid = airConditionerTypeValue && airConditionerTypeValue !== '';
    this.formUtilsService.toggleControl(airConditionerBTU, isValid);
    if (!isValid) {
      airConditionerBTU?.reset(null, { emitEvent: false });
      this.resetBrandCapacityModelControls();
    }
  }

  /**
   * Resets specific controls: price, pipe1Diameter, and pipe2Diameter.
   * This method does not reset all controls in the form.
   */
  private resetReadOnlyControls() {
    this.formGroup.get('price')?.reset(null, { emitEvent: false });
    this.formGroup.get('pipe1Diameter')?.reset(null, { emitEvent: false });
    this.formGroup.get('pipe2Diameter')?.reset(null, { emitEvent: false });
  }

  /**
   * Resets the controls for brand, capacity, and model.
   * This method will clear the values and reset the state of these controls.
   */
  private resetBrandCapacityModelControls() {
    this.formGroup.get('brand')?.reset(null, { emitEvent: false });
    this.formGroup.get('airConditionerBTU')?.reset(null, { emitEvent: false });
    this.formGroup.get('airConditionerModelId')?.reset(null, { emitEvent: false });
  }

  private onAirConditionerBTUChange() {
    const airConditionerBTU = this.formGroup.get('airConditionerBTU');
    airConditionerBTU?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async value => {
      if (value && value !== '') {
        const airConditionerTypeValue = this.formGroup.get('airConditionerType')?.value;
        this.modelFilter.capacity = value;
        await this.fillMarks(airConditionerTypeValue, value);
        await this.fillProducts(this.modelFilter);
      } else {
        this.modelFilter.capacity = undefined;
      }
      this.enableAirConditionerModelId();
    });
  }

  private onAirConditionerTypeChange() {
    const airConditionerType = this.formGroup.get('airConditionerType');
    airConditionerType?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async value => {
      this.resetBrandCapacityModelControls();
      if (value && value !== '') {
        this.modelFilter.airConditionerType = value;
        await this.fillMarks(value);
        await this.fillAirConditionerBTUOptions(value);
        this.enableAirConditionerModelId();
        await this.fillProducts(this.modelFilter);
      } else {
        this.modelFilter.airConditionerType = undefined;
      }
      this.enableAirConditionerModelId();
      this.enableBrand();
      this.enableAirConditionerBTU();
    });
  }

  private onBuyFromUsChange() {
    const buyFromUs = this.formGroup.get('buyFromUs');
    buyFromUs?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.changePrice(value);
      this.changePipeDiameters(value);
      this.changeBrandName(value);
      this.changeModelName(value);
      this.changeBrand(value);
      this.changeAirConditionerId(value);
    });
  }

  isEnable(controlName: string) {
    const control = this.formGroup.get(controlName);
    return control?.enabled;
  }

  private async fillAirConditionerTypes() {
    this.airConditionerTypeOptions = (await this.productsService.getAirConditionerTypes()).map((type: string) => ({
      value: type,
      label: type,
    }));
    this.formUtilsService.autoSelectSingleOption(
      this.formGroup.get('airConditionerType'),
      this.airConditionerTypeOptions,
    );
  }
}
