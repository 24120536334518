import { Component, OnInit } from '@angular/core';
import { labelsStep2, placeholders } from './step2.config';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss'],
})
export class Step2Component implements OnInit {
  labels = labelsStep2;
  placeholders = placeholders;
  destroy$ = new Subject<void>();
  loading = false;

  constructor(public formGroup: FormGroup) {}

  ngOnInit(): void {
    //
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
