import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WizardGeneralEventsService {
  private destroy$ = new Subject<void>();
  private subscribedControls: string[] = [];

  constructor() {
    //
  }

  destroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Subscribes to changes in a form control's value and executes provided callbacks.
   *
   * @param control - The form control to subscribe to. If not provided, no subscription is made.
   * @param delayTime - Optional delay time in milliseconds to debounce the value changes.
   * @param callbacks - A list of callback functions to execute when the control's value changes.
   *
   * This method subscribes to the `valueChanges` observable of the provided form control.
   * If a `delayTime` is specified, the value changes are debounced by the given time.
   * The method also tracks the control name in the `subscribedControls` array if it exists.
   * The subscription is automatically unsubscribed when the `destroy$` observable emits a value.
   */
  subscribeToFormControlChanges(control?: any, delayTime?: number, ...callbacks: ((value: any) => void)[]): void {
    let valueChanges$ = control?.valueChanges;
    if (delayTime) {
      valueChanges$ = valueChanges$?.pipe(debounceTime(delayTime));
    }
    if (valueChanges$) {
      const controlName = Object.keys(control.parent.controls).find(name => control === control.parent.controls[name]);
      if (controlName) {
        this.subscribedControls.push(controlName);
      }
    }
    valueChanges$?.pipe(takeUntil(this.destroy$)).subscribe((value: any) => {
      callbacks.forEach(callback => callback(value));
    });
  }

  isSubscribed(controlName: string): boolean {
    return this.subscribedControls.includes(controlName);
  }

  onAirConditionerBTUChange(formArray: FormArray) {
    const airConditionerBTU = formArray.at(0).get('airConditionerBTU');
    const surgeProtectorModelId = formArray.at(4).get('surgeProtectorModelId');
    airConditionerBTU?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      surgeProtectorModelId?.reset();
    });
  }
}
