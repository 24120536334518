import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { An507FormRefInterface, TextBoxField } from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import { Ability } from '@casl/ability';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { CreatedPdfService } from 'src/app/services/pdf/created-pdf.service';
import { UsersService } from 'src/app/services/users/users.service';
import { getError } from 'src/app/models/error';
import { ExportDataConfig } from '@protostech/protos-lib/lib/interfaces/export-data-config';
import { getDefaultExportDataConfig } from 'src/app/models/export-config';
import { AuthService } from 'src/app/services';
import { firstValueFrom } from 'rxjs';
import { RolesService } from 'src/app/services/roles/roles.service';
import { TechniciansService } from 'src/app/services/technicians/technicians.service';
import { getRole } from 'src/app/models/auth';
import moment from 'moment';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  @ViewChild('searchFormTemplate', { static: false }) searchFormId: An507FormRefInterface | undefined;
  @ViewChild('filterFormTemplate', { static: false }) filterFormId: An507FormRefInterface | undefined;

  userId: string | null = null;

  searchForm: TextBoxField[] = [
    {
      controlType: 'textBox',
      key: 'query',
      type: 'text',
      value: '',
      label: '',
      required: false,
      width: 'lg',
      noMargin: true,
      showInnerIcon: true,
      icon: '../assets/icons/search.svg',
      position: 'center',
      isSingleRow: true,
      placeholder: 'Escriba su búsqueda',
    },
  ];
  searchQuery: any = {
    page: 1,
    limit: 10,
  };

  userCanCreate = false;
  userCanUpdate = false;
  showAcceptButton = false;

  actionsTooltips = {
    deleteAction: 'Eliminar',
  };

  tableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };

  tableActions: Record<string, unknown> = {
    clickAction: (row: Record<string, unknown>) => this.editUser(row['id'] as string),
    deleteAction: (row: Record<string, unknown>) => this.deleteUser(row['id'] as string),
  };

  usersTable: any[] = [];

  tableColumns: any = [
    {
      prop: 'name',
      name: 'Nombre',
      minWidth: 400,
    },
    {
      prop: 'email',
      name: 'Email',
      minWidth: 270,
    },
    {
      prop: 'rol',
      name: 'Rol',
      minWidth: 250,
    },
    {
      prop: 'lastLogin',
      name: 'Última conexión',
      minWidth: 200,
      sortable: true,
      columnAlign: 'center',
    },
  ];

  tablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  showDeletionModal = false;
  isFetchingData = true;
  isExporting = false;
  showActionsButton = true;
  filterLabel: any;

  exportDataConfig: ExportDataConfig | undefined;

  topActions = {
    additionAction: () => this.addUser(),
  };

  topIcons = {
    additionIcon: '../../../assets/icons/add-icon.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };
  isTechnician: any;
  dateFormat = 'DD MMM YYYY';

  constructor(
    private userService: UsersService,
    private rolesService: RolesService,
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private ability: Ability,
    private pdfSvc: CreatedPdfService,
    private authService: AuthService,
    private technicianService: TechniciansService,
  ) {
    if (ability.can('delete', 'User')) {
      this.tableColumns.push({
        prop: 'delete',
        name: '',
        sortable: false,
        minWidth: 50,
        columnAlign: 'center',
      });
    }
  }

  async ngOnInit() {
    const me = await firstValueFrom(this.authService.getMe());
    this.isTechnician = this.technicianService.isTechnician(me.role.name);
    this.fillTable();

    this.route.queryParamMap.subscribe(async params => {
      const sortBy = params.get('sortBy');
      const sortOrder = params.get('sortOrder');
      const page = Number(params.get('page'));

      this.buildQuery(sortBy, sortOrder, page);
      if (page) {
        this.tablePagination['page'] = page;
      }

      this.fillTable(sortBy, sortOrder);
    });

    this.canCreate();
    if (!this.isTechnician) {
      await this.setExportationDataConfig();
    }
  }

  ngAfterViewInit() {
    this.searchForm[0].triggerOnEnter = this.searchOnEnter;
    this.searchForm[0].triggerOnBlur = this.searchOnBlur;
  }

  //Search
  buildQuery(sortKey?: string | null, sortOrder?: string | null, page?: number) {
    if (page) {
      Object.assign(this.searchQuery, page);
    }
    if (sortKey) {
      Object.assign(this.searchQuery, { sortKey, sortOrder });
    } else {
      Object.assign(this.searchQuery, { sortKey: 'name', sortOrder: 'asc' });
    }
  }

  searchOnEnter = (evt: any) => {
    if (evt.key === 'Enter') {
      const value = evt.target.value;
      this.updateSearchForm(value);
    }
  };

  searchOnBlur = (evt: any) => {
    this.updateSearchForm(evt.target.value);
  };

  updateSearchForm = (value: string) => {
    this.searchFormId?.form.setValue({ [this.searchForm[0].key]: value }, { emitEvent: true });
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsDirty();
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsTouched();
    this.search();
  };

  search = async () => {
    this.isFetchingData = true;
    if (!this.searchQuery['q']) {
      delete this.searchQuery['q'];
    }
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const users = await this.userService.getAllUsers(this.searchQuery);

        this.usersTable = users.data.map(user => {
          const roleName = getRole(user.role.id);
          return {
            id: user.id,
            name: user.name,
            email: user.email,
            rol: roleName !== '' ? roleName : user.role.name,
            lastLogin: moment.utc(user.lastLogin).format(this.dateFormat),
          };
        });

        this.tablePagination = { page: users.page, limit: users.limit, count: users.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  };

  async searchFormChanges(evt: { query: string } | unknown) {
    if ((evt as { query: string }).query) {
      this.searchQuery = {
        ...this.searchQuery,
        ['q']: (evt as { query: string }).query,
      };
    } else {
      this.resetSearchQuery();
    }
    this.search();
  }

  resetSearchQuery() {
    delete this.searchQuery['q'];
    this.searchQuery = {
      ...this.searchQuery,
      page: 1,
      limit: 10,
    };
  }

  addUser = () => {
    this.router.navigate(['/private/admin/users/new']);
  };

  deleteUser(userId: string) {
    this.userId = userId;
    this.showDeletionModal = true;
  }

  cancelDeletion = () => {
    this.userId = null;
    this.showDeletionModal = false;
  };

  confirmDeletion = () => {
    if (this.userId && this.userId !== '') {
      this.showActionsButton = false;
      this.userService
        .deleteUser(this.userId)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Usuario eliminado');
          this.fillTable();
          this.showDeletionModal = false;
          this.userId = null;
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
          this.showActionsButton = true;
        });
    }
  };

  editUser = async (userId: string) => {
    this.router.navigate(['/private/admin/users/edit'], { queryParams: { userId: userId } });
  };

  checkDuplicateErrorMessage(title: string, message: string, getBackendErrors?: boolean) {
    let duplicate: any;
    if (getBackendErrors) {
      const errorMessage = getError(message);
      duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
      message = errorMessage;
    } else {
      duplicate = this.toastr.findDuplicate(title, message, true, false);
    }
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  //Table
  async fillTable(sortKey?: string | null, sortOrder?: string | null) {
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const users = await this.userService.getAllUsers({
          page: this.tablePagination.page,
          limit: this.tablePagination.limit,
          sortKey: sortKey ? sortKey : 'name',
          sortOrder: sortOrder ? sortOrder : 'asc',
        });

        this.usersTable = users.data.map(user => {
          const roleName = getRole(user.role.id);
          return {
            id: user.id,
            lastLogin: moment.utc(user.lastLogin).format(this.dateFormat),
            name: user.name,
            email: user.email,
            rol: roleName !== '' ? roleName : user.role.name,
          };
        });

        this.tablePagination = { page: users.page, limit: users.limit, count: users.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  }

  handleChangePage(page: number) {
    this.tablePagination.page = page;
    this.router.navigate(['/private/admin/users'], { queryParams: { page }, queryParamsHandling: 'merge' });
    if (this.searchQuery['q']) {
      this.search();
    }
  }

  async onSortTable(evt: { dir: string; column: string }) {
    this.tablePagination.page = 1;
    this.router
      .navigate(['/private/admin/users'], {
        queryParams: { sortBy: evt.column, sortOrder: evt.dir, page: 1 },
        queryParamsHandling: 'merge',
      })
      .then(() => this.setExportationDataConfig());
  }

  goToRolesView = () => {
    this.router.navigate(['/private/admin/roles']);
  };

  onDeleteModalStatus(evt: { isOpen: boolean }) {
    this.showDeletionModal = evt.isOpen;
  }

  canCreate() {
    this.userCanCreate = this.ability.can('create', 'User');
  }

  canUpdate() {
    if (this.ability.cannot('update', 'User')) {
      this.userCanUpdate = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdate = true;
      this.showAcceptButton = false;
    }
  }

  //Report
  async setExportationDataConfig() {
    this.exportDataConfig = getDefaultExportDataConfig();
    this.exportDataConfig.fileName = 'Usuarios';
    this.exportDataConfig.data = await this.getReportData();
    this.exportDataConfig.config.columns = this.getReportColumns();
    this.exportDataConfig.config.pdfConfigOptions = { pdfColumnWidth: [] };
    this.exportDataConfig.config.pdfConfigOptions.pdfColumnWidth = [200, 100, 100, 100];
    this.exportDataConfig.config.pdfConfigOptions.pdfHeaderFontSize = 10;
    this.exportDataConfig.config.pageOritation = 'portrait';
  }

  async getReportData() {
    this.searchQuery['limit'] = 0;
    const users = await this.userService.getAllUsers(this.searchQuery);

    const userReport = users.data.map(user => {
      const roleName = getRole(user.role.id);
      return {
        name: user.name,
        email: user.email,
        rol: roleName !== '' ? roleName : user.role.name,
        lastLogin: moment.utc(user.lastLogin).format(this.dateFormat),
      };
    });
    return userReport;
  }

  private getReportColumns() {
    const columns = this.tableColumns
      .map((col: any) => {
        const reportCol = {
          header: col.name,
          key: col.prop,
          width: 20,
          style: {
            alignment: {
              horizontal: 'left' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          },
        };
        if (reportCol.key === 'email' || reportCol.key === 'role' || reportCol.key === 'status') {
          const propertyValue = {
            alignment: {
              horizontal: 'center' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          };
          Object.defineProperty(reportCol, 'style', {
            value: propertyValue,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        return reportCol;
      })
      .filter((col: any) => col.key !== 'delete');
    return columns;
  }
}
