<h3>Selección de Aire Acondicionado</h3>
<form *ngIf="formGroup" [formGroup]="formGroup">
    <prt-form-group>
        <prt-form-field size="xl">
            <prt-label>{{ labels.buyFromUs }}</prt-label>
            <prt-radio-button formControlName="buyFromUs" [options]="buyFromUsOptions" prtHostControl></prt-radio-button>
        </prt-form-field>
        <prt-form-field size="xl">
            <prt-label>{{ labels.airConditionerType }}</prt-label>
            <prt-dropdown appDefaultFocus formControlName="airConditionerType" [options]="airConditionerTypeOptions" prtHostControl></prt-dropdown>
        </prt-form-field>
        <prt-form-field *ngIf="!showInputs.brandName" size="lg">
            <prt-label>
                <span>{{ labels.brand }}</span>
            </prt-label>
            <prt-dropdown formControlName="brand" [options]="markOptions" [placeholder]="placeholders.brand" prtHostControl></prt-dropdown>
        </prt-form-field>
        <prt-form-field *ngIf="showInputs.brandName" size="lg">
            <prt-label>{{ labels.brandName }}</prt-label>
            <prt-input prtHostControl formControlName="brandName" [placeholder]="placeholders.brandName"></prt-input>
        </prt-form-field>
        <prt-form-field size="lg">
            <prt-label>
                <span>{{ labels.airConditionerBTU }}</span>
            </prt-label>
            <prt-dropdown formControlName="airConditionerBTU" [options]="airConditionerBTUOptions" [placeholder]="placeholders.airConditionerBTU" prtHostControl></prt-dropdown>
        </prt-form-field>
        <prt-form-field *ngIf="!showInputs.modelName" size="xl">
            <prt-label>
                <span>{{ labels.modelName }}</span>
            </prt-label>
            <prt-dropdown formControlName="airConditionerModelId" [options]="modelOptions" [placeholder]="placeholders.airConditionerModelId" prtHostControl></prt-dropdown>
        </prt-form-field>
        <prt-form-field *ngIf="showInputs.modelName" size="xl">
            <prt-label>{{ labels.airConditionerModelId }}</prt-label>
            <prt-input prtHostControl formControlName="modelName" [placeholder]="placeholders.modelName"></prt-input>
        </prt-form-field>
        <prt-form-field *ngIf="showInputs.price" size="md">
            <prt-label>{{ labels.price }}</prt-label>
            <prt-input prtHostControl formControlName="price" [placeholder]="placeholders.price"></prt-input>
        </prt-form-field>
        <prt-form-field [size]="showInputs.price ?  'md' : 'lg'">
            <prt-label>{{ labels.pipe1Diameter }}</prt-label>
            <prt-dropdown *ngIf="isEnable('pipe1Diameter')" formControlName="pipe1Diameter" [options]="pipeDiameter1Options" [placeholder]="placeholders.pipe1Diameter" prtHostControl></prt-dropdown>
            <prt-input *ngIf="!isEnable('pipe1Diameter')"  prtHostControl formControlName="pipe1Diameter"></prt-input>
        </prt-form-field>
        <prt-form-field [size]="showInputs.price ?  'md' : 'lg'">
            <prt-label>{{ labels.pipe2Diameter }}</prt-label>
            <prt-dropdown *ngIf="isEnable('pipe2Diameter')" formControlName="pipe2Diameter" [options]="pipeDiameter2Options" [placeholder]="placeholders.pipe2Diameter" prtHostControl></prt-dropdown>
            <prt-input *ngIf="!isEnable('pipe2Diameter')"  prtHostControl formControlName="pipe2Diameter"></prt-input>
        </prt-form-field>
    </prt-form-group>
</form>
<app-loading class="step-loading" [show]="loading"></app-loading>
