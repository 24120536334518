import { Validators } from '@angular/forms';

export const labelsStep2 = {
  labor: 'Valor ($)',
};

export const placeholders = {
  labor: '$',
};

export const step2Controls = {
  labor: ['', [Validators.min(0)]],
};
