<ng-container *ngIf="!isLoading">
  <prt-layout
    [useLegacyIcons]="false"
    [sideNavData]="layoutSideNavData"
    [mobileFrameLess]="true"
    [frameLess]="true"
    [sideNavMobile]="true"
    [hasSideNav]="true"
    [sideNavShadow]="true"
    [ngClass]="{ 'technitian-header-style': true}"
    [sideNavVersion]="'v1'">
    <router-outlet></router-outlet>
  </prt-layout>

  <!-- <main class="layout__main" *ngIf="isPhone && isTechnician">
        <div class="layout__content-wrapper">
            <div [ngClass]="{'no-padding': checkIsQuotationSend()}" class="layout__content-box">
                <div class="layout__body-wrapper">
                    <router-outlet></router-outlet>
                </div>
                <div class="nav-bar-wrapper" *ngIf="navBarOptionsLoaded">
                  <ng-container *ngIf="useLegacyIcons">
                    <div class="nav-bar__option-wrapper" *ngFor="let option of navBarOptions; let i = index"
                         (click)="selectOption(i, option.route.path, option.route.params)"
                         [ngClass]="{'active': activeNavBarOption === i}">
                      <img [src]="option.img" [alt]="option.label">
                      <h5>{{option.label}}</h5>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="!useLegacyIcons">
                    <div class="nav-bar__option-wrapper-svg" *ngFor="let option of navBarOptions; let i = index"
                         (click)="selectOption(i, option.route.path, option.route.params)"
                         [ngClass]="{'active': activeNavBarOption === i}">
                      <div [id]="'svg#' + (i + 1)" class="nav-bar__option-wrapper-svg-icon"></div>
                      <h5>{{option.label}}</h5>
                    </div>
                  </ng-container>
                </div>
            </div>
        </div>
    </main> -->
  <prt-modal [displayButton]="false" size="sm" [isActive]="showModal" (onAction)="onModalStatus($event)"
    [hasCloseButton]="false">
    <div class="modal-wrapper">
      <h2 style="text-align: center" class="text-primary"> Confirmación </h2>
      <span class="paragraph-1">¿Deseas finalizar la sesión?</span>
      <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
        <prt-button [isInverted]="true" type="outline" class="prt__button--outline" [callback]="returnDorita">
          Cancelar
        </prt-button>
        <prt-button [isInverted]="true" type="solid" class="prt__button--solid" [callback]="confirmationExit">
          Aceptar
        </prt-button>
      </div>
    </div>
  </prt-modal>
</ng-container>
