import { Component, OnInit } from '@angular/core';
import { FormArray, FormGroup, Validators } from '@angular/forms';
import { labelsStep4, placeholdersStep4, yesOrNotOptions } from './step4.config';
import { Subject, takeUntil } from 'rxjs';
import { PrtOptionDropdownModel, PrtOptionRadioButtonModel } from '@protostech/protos-lib/v2';
import { FormUtilsService } from 'src/app/services/utils/form-utils.service';
import { ProductsService } from 'src/app/services/products/products.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-step4',
  templateUrl: './step4.component.html',
  styleUrls: ['./step4.component.scss'],
})
export class Step4Component implements OnInit {
  labels = labelsStep4;
  placeholders = placeholdersStep4;
  yesOrNotOptions: PrtOptionRadioButtonModel[] = yesOrNotOptions;
  condensatePumpsModelIdOptions: PrtOptionDropdownModel[] = [];
  surgeProtectorModelIdOptions: PrtOptionDropdownModel[] = [];
  loading = false;
  showInputs = {
    condensatePumpsAmount: false,
    condensatePumpsModelId: false,
    needCondensatePumps: false,
    surgeProtectorModelId: false,
  };
  destroy$ = new Subject<void>();

  constructor(
    private formUtilsService: FormUtilsService,
    private productsService: ProductsService,
    private formArray: FormArray,
    public formGroup: FormGroup,
    private toastr: ToastrService,
  ) {
    //
  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.subscribeToEvent();
    await this.fillForm();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async fillForm() {
    const needSurgeProtector = this.formGroup.get('needSurgeProtector');
    const needCondensatePumps = this.formGroup.get('needCondensatePumps');
    const airConditionerBTU = this.formArray.at(0).get('airConditionerBTU');
    if (needCondensatePumps?.value) {
      this.changeCondensatePumpsAmount(needCondensatePumps.value);
      await this.changecondensatePumpsModelId(needCondensatePumps.value);
    }
    await this.fillsurgeProtectorModelId(airConditionerBTU?.value);
    await this.fillcondensatePumpsModelId();
    this.showInputs.needCondensatePumps = this.condensatePumpsModelIdOptions.length > 0;
    this.showCondensatePumpsInputs(needCondensatePumps?.value);
    this.changesurgeProtectorModelId(needSurgeProtector?.value);
  }

  private subscribeToEvent() {
    this.onNeedCondensatePumpsChange();
    this.onNeedSurgeProtector();
  }

  private onNeedSurgeProtector() {
    const needSurgeProtector = this.formGroup.get('needSurgeProtector');
    needSurgeProtector?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async value => {
      await this.changesurgeProtectorModelId(value);
    });
  }

  private async changesurgeProtectorModelId(value: string) {
    const surgeProtectorModelId = this.formGroup.get('surgeProtectorModelId');
    if (value === 'true') {
      const airConditionerBTU = this.formArray.at(0).get('airConditionerBTU');
      await this.fillsurgeProtectorModelId(airConditionerBTU?.value);
    } else {
      this.resetsurgeProtectorModelId();
    }
    this.showInputs.surgeProtectorModelId = value === 'true' && this.surgeProtectorModelIdOptions.length > 0;
    console.log('surgeProtectorModelId', this.showInputs.surgeProtectorModelId);
    this.formUtilsService.updateValidators(surgeProtectorModelId, this.showInputs.surgeProtectorModelId);
  }

  private onNeedCondensatePumpsChange() {
    const needCondensatePumps = this.formGroup.get('needCondensatePumps');
    needCondensatePumps?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async value => {
      this.changeCondensatePumpsAmount(value);
      await this.changecondensatePumpsModelId(value);
      this.showCondensatePumpsInputs(value);
    });
  }

  private changeCondensatePumpsAmount(value: string) {
    const showCondensatePumpsAmount = value === 'true';
    const condensatePumpsAmount = this.formGroup.get('condensatePumpsAmount');
    this.formUtilsService.updateValidators(condensatePumpsAmount, showCondensatePumpsAmount);
    if (showCondensatePumpsAmount) {
      condensatePumpsAmount?.addValidators(Validators.min(1));
      condensatePumpsAmount?.updateValueAndValidity();
    }
  }

  private async changecondensatePumpsModelId(value: string) {
    if (value === 'true') {
      await this.fillcondensatePumpsModelId();
    }
    this.formUtilsService.updateValidators(this.formGroup.get('condensatePumpsModelId'), value === 'true');
  }

  private showCondensatePumpsInputs(value: string) {
    this.showInputs.condensatePumpsModelId = value === 'true';
    this.showInputs.condensatePumpsAmount = value === 'true';
  }

  private async fillcondensatePumpsModelId() {
    const response = await this.productsService.getCondensatePumps();
    this.condensatePumpsModelIdOptions = response.map((item: any) => ({ label: item.name, value: item.id }));
    this.formUtilsService.autoSelectSingleOption(
      this.formGroup.get('condensatePumpsModelId'),
      this.condensatePumpsModelIdOptions,
    );
  }

  private resetsurgeProtectorModelId() {
    const surgeProtectorModelId = this.formGroup.get('surgeProtectorModelId');
    surgeProtectorModelId?.reset();
    this.surgeProtectorModelIdOptions = [];
  }

  private async fillsurgeProtectorModelId(value: string) {
    if (value) {
      const response: any = await this.productsService
        .getVoltageProtector(value)
        .catch(error => this.getError(error))
        .finally(() => (this.loading = false));
      this.surgeProtectorModelIdOptions = response.map((item: { name: string; id: string }) => ({
        label: item.name,
        value: item.id,
      }));
      this.formUtilsService.autoSelectSingleOption(
        this.formGroup.get('surgeProtectorModelId'),
        this.surgeProtectorModelIdOptions,
      );
      this.formUtilsService.assignFirstOption(
        this.formGroup.get('surgeProtectorModelId'),
        this.surgeProtectorModelIdOptions,
      );
    } else {
      this.surgeProtectorModelIdOptions = [];
    }
  }

  private getError(err: any) {
    console.error(err);
    if (err.error.message) {
      this.checkDuplicateErrorMessage('Error', err.error.message);
    } else {
      this.checkDuplicateErrorMessage('Error', err.error.code);
    }
  }

  private checkDuplicateErrorMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }
}
