export const labelsStep4 = {
  needSurgeProtector: '¿Necesita Protector de Voltaje?',
  needPowerCable: '¿Necesita Cable Potencia?',
  needCondensatePumps: '¿Necesita Bombas de Condensado?',
  condensatePumpsAmount: 'Cantidad de Bombas',
  condensatePumpsModelId: 'Producto',
  surgeProtectorModelId: 'Producto',
};

export const placeholdersStep4 = {
  condensatePumpsAmount: 'Cantidad de Bombas',
  surgeProtectorModelId: 'Seleccione producto',
  condensatePumpsModelId: 'Seleccione producto',
};

export const step4Controls = {
  needSurgeProtector: ['false'],
  needPowerCable: ['false'],
  needCondensatePumps: ['false'],
  condensatePumpsAmount: [1],
  condensatePumpsModelId: [null],
  surgeProtectorModelId: [null],
};

export const yesOrNotOptions = [
  { value: 'true', label: 'Si' },
  { value: 'false', label: 'No' },
];
