<prt-modal class="categories" [displayButton]="false" size="md" [isActive]="show"
    (onAction)="modalStatusChange($event)" [hasCloseButton]="false">
    <div class="modal-wrapper">
        <h2>Seleccione el producto que desea agregar</h2>
        <div class="create__form-wrapper">
            <div class="table-external-wrapper  categories-table">
                <form *ngIf="filterForm" [formGroup]="filterForm">
                    <prt-form-group>
                        <prt-form-field class="search" size="lg">
                            <prt-label></prt-label>
                            <img src="assets/icons/search.svg" alt="search icon" style="width: 20px;">
                            <prt-input prtHostControl formControlName="q" [placeholder]="'Escriba su busqueda'"></prt-input>
                        </prt-form-field>
                        <prt-form-field size="md">
                            <prt-dropdown formControlName="categoryId" [options]="categoriesOptions" placeholder="Filtrar por categoría" prtHostControl></prt-dropdown>
                        </prt-form-field>
                    </prt-form-group>
                </form>
                <p-table class="products-filter"
                    *ngIf="loadingData"
                    [rows]="rows" 
                    [columns]="columns"
                    [pagination]="pagination"
                    [innerActions]="innerActions"
                    [isLoading]="!loadingData"
                    [showLoading]="fetchingData"
                    [resultLabel]="'Productos'" columnMode="force" [showActionsAtStart]="true"
                    [staticRowActions]="true" 
                    [hasTopActions]="true" 
                    [topActionsWithLabels]="true"
                    [hasSearch]="false"
                    [enableAutoSearch]="true"
                    [showPagination]="true"
                    [showLoading]="loadingData"
                    (changeCurrentPage)="changePage($event)"
                    (onSorting)="onSort($event)"
                    >
                </p-table>
            </div>
        </div>
        <div class="modal-buttons-wrapper--absolute">
            <prt-button [isInverted]="true" type="outline" [callback]="closeModal"> Cancelar
            </prt-button>
        </div>
    </div>
</prt-modal>