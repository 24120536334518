import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Quotation } from 'src/app/models/quotation';
import { QuotationsService } from 'src/app/services/quotations/quotations.service';
import { QuotationStatus, quotationStatusTranslations } from 'src/app/enums/quotation-status';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrtOptionDropdownModel } from '@protostech/protos-lib/v2';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss'],
})
export class PdfViewerComponent implements OnInit {
  quotation!: Quotation;
  pdfUrl!: SafeResourceUrl;
  statusTranslation: any = quotationStatusTranslations;
  stauts: any = QuotationStatus;
  isMobile: boolean = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  form!: FormGroup;
  destroy$ = new Subject<void>();
  statusTypeOptions: PrtOptionDropdownModel[] = [];
  statusType = this.formBuilder.control('');
  showCloneModal = false;
  showCancelModal = false;
  showMarkAsPaidModal = false;
  showShareModal = false;
  showNotAllowMessage = false;
  loadingPdf = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private quotationsService: QuotationsService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) {}

  async ngOnInit(): Promise<void> {
    const id = this.route.snapshot.queryParams['quotationId'];
    await this.getQuotation(id);
    await this.getPdf(id);
    this.buildForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private buildForm() {
    this.statusType.setValue(this.quotation.status, { emitEvent: false });
    this.subscribeStatusTypeChange();
    this.fillStatusType();
  }

  private subscribeStatusTypeChange() {
    this.statusType?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      if (value && value !== '') {
        switch (value as QuotationStatus) {
          case QuotationStatus.CANCELED:
            this.showCancelQuotationModal();
            break;
          case QuotationStatus.PAID:
            this.showMarkAsPaidQuotationModal();
            break;
        }
      } else {
        this.statusType.setValue(this.quotation.status, { emitEvent: false });
      }
    });
  }

  private hexToBase64(hexString: string): string {
    const byteArray = new Uint8Array(hexString.match(/.{1,2}/g)!.map(byte => parseInt(byte, 16)));
    let binary = '';
    byteArray.forEach(byte => (binary += String.fromCharCode(byte)));
    return btoa(binary);
  }

  /**
   * Fills the status type options based on the current quotation status.
   *
   * This method generates a list of status type options that can be used to update the status of a quotation.
   * It filters the available status types based on the current status of the quotation and maps them to their corresponding labels.
   *
   * The available status transitions are defined in the `options` object:
   * - `QuotationStatus.ISSUED` can transition to `QuotationStatus.ISSUED`, `QuotationStatus.CANCELED`, or `QuotationStatus.PAID`.
   * - `QuotationStatus.CANCELED` can transition to `QuotationStatus.ISSUED`.
   * - `QuotationStatus.PAID` can transition to `QuotationStatus.ISSUED`.
   *
   * The labels for the status types are defined in the `labels` object:
   * - `QuotationStatus.CANCELED` is labeled as 'Marcar como anulada'.
   * - `QuotationStatus.PAID` is labeled as 'Marcar como pagada'.
   *
   * The method updates the `statusTypeOptions` property with the filtered and mapped status type options.
   */
  private fillStatusType() {
    const options: any = {
      [QuotationStatus.ISSUED]: [QuotationStatus.ISSUED, QuotationStatus.CANCELED, QuotationStatus.PAID],
      [QuotationStatus.CANCELED]: [QuotationStatus.ISSUED],
      [QuotationStatus.PAID]: [QuotationStatus.ISSUED],
    };
    this.statusTypeOptions = Object.keys(QuotationStatus)
      .filter(key => {
        const status = this.quotation.status;
        return options[status]?.includes(key);
      })
      .map(key => {
        const labels: any = {
          [QuotationStatus.CANCELED]: 'Marcar como anulada',
          [QuotationStatus.PAID]: 'Marcar como pagada',
        };
        const status = key as keyof typeof QuotationStatus;
        return {
          value: QuotationStatus[status],
          label: key === QuotationStatus.ISSUED ? this.statusTranslation[status] : labels[status],
        };
      });
  }

  async getQuotation(id: string) {
    this.quotation = await this.quotationsService.getQuotationById(id);
  }

  async getPdf(id: string) {
    this.loadingPdf = true;
    await this.quotationsService
      .getPdf(id)
      .then(response => {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          `data:application/pdf;base64,${this.hexToBase64(response)}`,
        );
      })
      .catch(error => (this.showNotAllowMessage = true))
      .finally(() => (this.loadingPdf = false));
  }

  cancel = () => {
    this.router.navigate(['/private/quotations']);
  };

  cancelQuotation = () => {
    this.quotationsService
      .cancelQuotation(this.quotation.id)
      .then(() => {
        this.toastr.success('Cotización anulada');
        this.cancel();
      })
      .catch(err => this.getError(err));
  };

  share = () => {
    if (navigator.share) {
      const base64Data = (this.pdfUrl as any).changingThisBreaksApplicationSecurity.split(',')[1];
      const blob = new Blob([Uint8Array.from(atob(base64Data), c => c.charCodeAt(0))], { type: 'application/pdf' });
      const file = new File([blob], `Cotizacion #${this.quotation.numberStr}.pdf`, { type: 'application/pdf' });
      navigator
        .share({
          title: 'Compartir Cotización',
          files: [file],
        })
        /* .then(() => {
          this.checkDuplicateSuccessMessage('', 'Cotización duplicada');
        }) */
        .catch(err => {
          console.error('Error al compartir:', err);
        });
    } else {
      this.toastr.error('La funcionalidad de compartir no está soportada en este navegador.');
    }
  };

  shareLink = () => {
    this.quotationsService
      .getPaymentLink(this.quotation.id)
      .then((paymentLink: any) => {
        if (navigator.share) {
          const shareData = {
            title: 'Compartir Cotización',
            text: `Link de pago Cotizacion #${this.quotation.numberStr}: ${paymentLink.checkoutId}`,
          };
          navigator
            .share(shareData)
            .then(() => {
              this.toastr.success('Enlace compartido exitosamente');
            })
            .catch(err => {
              console.error('Error al compartir:', err);
              this.toastr.error('Error al compartir el enlace');
            });
          this.copyTextToClipboard(paymentLink.checkoutId);
        } else {
          this.toastr.error('La funcionalidad de compartir no está soportada en este navegador.');
        }
      })
      .catch(err => {
        console.error('Error al compartir:', err);
        this.toastr.error('Ha ocurrido un error al intentar compartir link de pago.');
      });
  };

  downloadPdf = () => {
    const link = document.createElement('a');
    const base64Data = (this.pdfUrl as any).changingThisBreaksApplicationSecurity.split(',')[1];
    const blob = new Blob([Uint8Array.from(atob(base64Data), c => c.charCodeAt(0))], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = `Cotización #${this.quotation.numberStr}.pdf`;
    link.click();
    URL.revokeObjectURL(url);
  };

  private copyTextToClipboard = (content: string) => {
    if (typeof content === 'string') {
      navigator.clipboard.writeText(content).then(
        () => this.toastr.success('Contenido copiado al portapapeles'),
        err => this.toastr.error('Error al copiar el contenido'),
      );
    }
  };

  duplicate = async () => {
    const quotation = await this.quotationsService.getQuotationById(this.quotation?.id || '');
    const data: any = {
      note: quotation?.note || '',
      clientId: quotation?.clientId || '',
      userId: quotation?.userId || '',
      products:
        quotation?.products.map((product: any) => ({
          id: product.product.id || '',
          quantity: product.quantity,
          totalPrice: product.totalPrice,
          unitPrice: product.unitPrice,
        })) || [],
    };
    this.quotationsService
      .createQuotation(data)
      .then((response: any) => {
        this.router.navigate(['/private/quotations/edit'], { queryParams: { quotationId: response.id } });
        this.checkDuplicateSuccessMessage('', 'Cotización duplicada. Esta es la nueva copia.');
        this.showCloneModal = false;
        this.router.navigate(['/private/quotations/edit'], { queryParams: { quotationId: response.id } });
      })
      .catch(err => this.getError(err));
  };

  markAsPaid = () => {
    this.quotationsService
      .markAsPaid(this.quotation.id)
      .then(() => {
        this.toastr.success('Cotización marcada como pagada');
        this.cancel();
      })
      .catch(err => this.getError(err));
  };

  private getError(err: any) {
    console.error(err);
    if (err.error.code) {
      this.checkDuplicateErrorMessage('Error', err.error.code);
    } else {
      this.checkDuplicateErrorMessage('Error', err.error.message);
    }
  }

  showCancelQuotationModal = () => {
    this.showCancelModal = true;
  };

  showDuplicateQuotationModal = () => {
    this.showCloneModal = true;
  };

  onCloneModalConfirmation(evt: { isOpen: boolean }) {
    this.showCloneModal = evt.isOpen;
  }

  onCancelModalConfirmation(evt: { isOpen: boolean }) {
    this.showCancelModal = evt.isOpen;
  }

  cancelClone = () => {
    this.showCloneModal = false;
  };

  closeCancelQuotation = () => {
    this.showCancelModal = false;
    this.statusType.setValue(this.quotation.status, { emitEvent: false });
  };

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  checkDuplicateErrorMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  showMarkAsPaidQuotationModal = () => {
    this.showMarkAsPaidModal = true;
  };

  onMarkAsPaidModalConfirmation = (evt: { isOpen: boolean }) => {
    this.showMarkAsPaidModal = evt.isOpen;
  };

  closeMarkAsPaidQuotation = () => {
    this.showMarkAsPaidModal = false;
    this.statusType.setValue(this.quotation.status, { emitEvent: false });
  };

  showShareQuotationModal = () => {
    this.showShareModal = true;
  };

  onShareModalConfirmation = (evt: { isOpen: boolean }) => {
    this.showShareModal = evt.isOpen;
  };
}
