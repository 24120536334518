<h3>Complementos Adicionales 2</h3>
<form *ngIf="formGroup" [formGroup]="formGroup">
    <prt-form-group>
        <prt-form-field size="xl">
            <prt-label>
                <span>{{ labels.needPropane }}</span>
            </prt-label>
            <prt-radio-button formControlName="needPropane" [options]="yesOrNotOptions"
                prtHostControl></prt-radio-button>
        </prt-form-field>
        <prt-form-field [size]="showInputs.filterDryerOrGlassType ? 'lg' : 'xl'">
            <prt-label>
                <span>{{ labels.needGlassAndFilterDryer }}</span>
            </prt-label>
            <prt-radio-button formControlName="needGlassAndFilterDryer" [options]="yesOrNotOptions"
                prtHostControl></prt-radio-button>
        </prt-form-field>
        <prt-form-field *ngIf="showInputs.filterDryerOrGlassType" [size]="showInputs.filterDryerOrGlassType ? 'lg' : 'xl'">
          <prt-label>
              <span>{{ labels.filterDryerOrGlassType }}</span>
          </prt-label>
          <prt-dropdown formControlName="filterDryerOrGlassType" [options]="filterDryerOrGlassTypeOptions" [placeholder]="placeholders.filterDryerOrGlassType"
              prtHostControl></prt-dropdown>
        </prt-form-field>
        <prt-form-field size="xl">
            <prt-label>
                <span>{{ labels.needThermostat }}</span>
            </prt-label>
            <prt-radio-button prtHostControl formControlName="needThermostat"
                [options]="yesOrNotOptions"></prt-radio-button>
        </prt-form-field>
        <prt-form-field size="lg">
            <prt-label>
                <span>{{ labels.needWeldingRods }}</span>
            </prt-label>
            <prt-radio-button formControlName="needWeldingRods" [options]="yesOrNotOptions" prtHostControl></prt-radio-button>
        </prt-form-field>
        <prt-form-field *ngIf="showInputs.weldingRodsAmount" size="lg">
            <prt-label>
                <span>{{ labels.weldingRodsAmount }}</span>
            </prt-label>
            <prt-input type="number" formControlName="weldingRodsAmount" [placeholder]="placeholders.weldingRodsAmount"
                prtHostControl></prt-input>
        </prt-form-field>
    </prt-form-group>
</form>
<app-loading class="step-loading" [show]="loading"></app-loading>
