<div class="content-wrapper">
    <div class="header-with-tabs">
        <div class="header-wrapper">
            <h1 class="text-primary"> {{tabSelected === 'PRODUCTS' ? 'Productos' : 'Categorías'}} </h1>
        </div>
        <div class="tab-menu-wrapper">
            <div class="tab-option" *ngFor="let tabOption of tabMenuOptions" [ngClass]="{'selected': tabSelected === tabOption.key}"
            (click)="tabSelected = tabOption.key; cleanSearch(tabOption.key); setExportationDataConfig(tabOption.key)">
                <span>{{tabOption.label}}</span>
            </div>
        </div>
    </div>
    <div class="table-external-wrapper table-with-tabs" *ngIf="tabSelected === 'PRODUCTS'">
        <p-table
            [rows]="productsTable"
            [columns]="productsTableColumns"
            [innerIcons]="tableIcons"
            [pagination]="productsTablePagination"
            (changeCurrentPage)="handleProductsChangePage($event)"
            [resultLabel]="'Productos'"
            [showLoading]="true"
            [isLoading]="isFetchingData"
            [isExporting]="isExporting"
            (onSorting)="onSortProductsTable($event)"
            [hasSearch]="true"
            [searchForm]="searchForm"
            (onSearchChange)="searchFormChanges($event)"
            [filterActionLabel]="filterLabel"
            [actionsTooltips]="actionsTooltips"
            [showActionsAtStart]="true"
            [staticRowActions]="true"
            columnMode="force"
            [exportDataConfig]="productExportDataConfig"
            [topActions]="topActions"
            [topIcons]="topIcons"
            [enableAutoSearch]="true"
            [topActionsWithLabels]="true"
            [hasTopActions]="true"
            [additionActionLabel]="'Agregar nuevo'"
            (onDownloadAction)="getProductReportData()"
            [showPagination]="true">
        </p-table>
    </div>
    <div class="table-external-wrapper table-with-tabs" *ngIf="tabSelected === 'CATEGORIES'">
        <p-table class="category-table"
            [rows]="categoriesTable"
            [columns]="categoriesTableColumns"
            [innerIcons]="tableIcons"
            [innerActions]="categoryTableActions"
            [pagination]="categoriesTablePagination"
            (changeCurrentPage)="handleCategoriesChangePage($event)"
            [resultLabel]="'Categorías'"
            [showLoading]="true"
            [isLoading]="isFetchingData"
            [isExporting]="isExporting"
            (onSorting)="onSortCategoriesTable($event)"
            [hasSearch]="true"
            [searchForm]="searchForm"
            (onSearchChange)="searchFormChanges($event)"
            [filterActionLabel]="filterLabel"
            [actionsTooltips]="actionsTooltips"
            [showActionsAtStart]="true"
            [staticRowActions]="true"
            columnMode="force"
            [exportDataConfig]="categoryExportDataConfig"
            [topActions]="categoryTableActions"
            [topIcons]="topIcons"
            [enableAutoSearch]="true"
            [topActionsWithLabels]="true"
            [hasTopActions]="true"
            [additionActionLabel]="'Agregar nueva'"
            (onDownloadAction)="getCategoryReportData()"
            [showPagination]="true">
        </p-table>
    </div>

    <prt-modal [displayButton]="false" size="sm" [isActive]="showDeletionModal" (onAction)="onDeleteModalStatus($event)"
    [hasCloseButton]="false">
        <div class="modal-wrapper">
            <h2 style="text-align: center" class="text-primary"> Confirmación </h2>
            <span class="paragraph-1">¿Estás seguro que deseas eliminar este elemento?</span>
            <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
                <prt-button [isInverted]="true" type="outline" [callback]="cancelDeletion">
                    Cancelar
                </prt-button>
                <prt-button [isInverted]="true" type="solid" [callback]="confirmDeletion">
                    Eliminar
                </prt-button>
            </div>
        </div>
    </prt-modal>
</div>