import { Validators } from '@angular/forms';
import { Unit, UnitTranslation } from 'src/app/enums/unit';

export const labelsStep1 = {
  pipesType: 'Tipo de Tuberías',
  pipesJoined: '¿Las tuberías van juntas?',
  pipesDistance: 'Distancia (Cond. - Evap.)',
  pipesDistanceUnit: 'Unidad',
};

export const placeholdersStep1 = {
  pipesDistance: '-',
  pipesDistanceUnit: 'Unidad',
  pipesType: 'Seleccione tipo de tuberías',
};

export const step1Controls = {
  pipesType: ['PEX', Validators.required],
  pipesJoined: ['true', Validators.required],
  pipesDistance: ['', [Validators.required, Validators.min(0)]],
  pipesDistanceUnit: [Unit.METROS, [Validators.required]],
};

export const pipesGoTogetherOptions = [
  { value: 'true', label: 'Si' },
  { value: 'false', label: 'No' },
];

export const pipesDistanceUnitOptions = [
  { value: Unit.METROS, label: UnitTranslation[Unit.METROS] },
  { value: Unit.PIES, label: UnitTranslation[Unit.PIES] },
];
