<div class="create__content-wrapper no-padding">
    <div class="create__header-wrapper">
        <h1 class="text-primary" *ngIf="userCanUpdate || !clientId"> {{!clientId ?
            'Agregar Nuevo Cliente' : 'Editar Cliente'}} </h1>
        <h1 class="text-primary" *ngIf="!userCanUpdate && clientId"> Detalle del Cliente </h1>
    </div>
    <div class="create__body-wrapper">
        <div class="create__form-wrapper">
            <div class="create__form-box">
                <prt-form #formTemplate [fields]="clientForm" (change)="createClientFormChanges($event)"
        (isFormValid)="createClientFormISValid($event)" [validateIsRequired]="true"></prt-form>
            </div>
        </div>
    </div>
    <div class="create__footer-wrapper" *ngIf="userCanUpdate || !clientId">
        <prt-button [isInverted]="true" type="solid" [callback]="createClient">
            Guardar
        </prt-button>
        <prt-button type="outline" [callback]="cancelCreation">
            Cancelar
        </prt-button>
        
    </div>
    <div class="create__footer-wrapper" *ngIf="!userCanUpdate && clientId">
        <prt-button [isInverted]="true" type="solid" [callback]="cancelCreation">
            Aceptar
        </prt-button>
    </div>
</div>
