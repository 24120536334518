import { Validators } from '@angular/forms';

export const labelsStep3 = {
  needAirBase: '¿Necesita Base del Aire?',
  airBaseModelId: ' Producto',
  airBasesAmount: 'Cantidad de Bases',
  needLubricant: '¿Necesita Lubricante?',
  needTape: '¿Necesita Tape de Tela?',
  needPoFoam: '¿Necesita Foam de Poliuretano?',
  baseTypes: 'Tipo de Producto',
};

export const placeholdersStep3 = {
  airBaseModelId: 'Seleccione producto',
  baseTypes: 'Seleccione tipo de producto',
  airBasesAmount: 'Cantidad de Bases',
};

export const step3Controls = {
  needAirBase: ['false'],
  airBaseModelId: [null],
  airBasesAmount: [null],
  needLubricant: ['false'],
  needTape: ['false'],
  needPoFoam: ['false'],
  baseTypes: [null],
};

export const yesOrNotOptions = [
  { value: 'true', label: 'Si' },
  { value: 'false', label: 'No' },
];
