import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appDefaultFocus]',
})
export class InputFocusDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const childElement = this.getFirstInputElement();
    if (childElement) {
      childElement.focus();
    }
  }

  getFirstInputElement(): HTMLInputElement | null {
    const inputs = this.el.nativeElement.querySelectorAll('input');
    for (const input of inputs) {
      if (!input.value) {
        return input;
      }
    }
    return null;
  }
}
