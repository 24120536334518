<h3>Selección de Tuberías</h3>
<form *ngIf="formGroup" [formGroup]="formGroup">
    <prt-form-group>
        <prt-form-field size="lg">
            <prt-label>{{ labels.pipesType }}</prt-label>
            <prt-dropdown formControlName="pipesType" [options]="pipeTypeOptions" [placeholder]="placeholders.pipesType" prtHostControl></prt-dropdown>
        </prt-form-field>
        <prt-form-field size="lg">
            <prt-label>{{ labels.pipesJoined }}</prt-label>
            <prt-radio-button formControlName="pipesJoined" [options]="pipesGoTogetherOptions" prtHostControl></prt-radio-button>
        </prt-form-field>
        <prt-form-field size="lg">
            <prt-label><span title="(Condensador - Evaporador)">{{ labels.pipesDistance }}</span></prt-label>
            <div class="distance">
                <prt-input appDefaultFocus type="number" prtHostControl formControlName="pipesDistance" [placeholder]="placeholders.pipesDistance"></prt-input>
                <prt-dropdown class="unit" formControlName="pipesDistanceUnit" [options]="pipesDistanceUnitOptions" [placeholder]="placeholders.pipesDistanceUnit" prtHostControl></prt-dropdown>
            </div>
        </prt-form-field>
    </prt-form-group>
</form>
<app-loading class="step-loading" [show]="loading"></app-loading>
