<div class="create__content-wrapper">
    <div class="header-with-tabs">
        <div class="create__header-wrapper create__header-wrapper--column">
            <h1 class="text-primary" *ngIf="userCanUpdate || !technicianId"> {{!technicianId ?
                'Agregar Nuevo Técnico' : 'Editar Técnico'}} </h1>
            <h1 class="text-primary" *ngIf="!userCanUpdate && technicianId"> Detalle del Técnico </h1>
            <h2 *ngIf="technicianId"> {{technicianValues.name}}</h2>
        </div>
        <div class="tab-menu-wrapper" *ngIf="technicianId">
            <div class="tab-option" *ngFor="let tabOption of tabMenuOptions"
                [ngClass]="{'selected': tabSelected === tabOption.key}"
                (click)="tabSelected = tabOption.key; handleChangedTab(tabOption.key)">
                <span>{{tabOption.label}}</span>
            </div>
        </div>
    </div>

    <div class="create__body-wrapper create__body-wrapper--no-center no-overflow-bar" *ngIf="tabSelected === 'INFORMATION'">
        <div class="create__img-wrapper">
            <div class="img-box">
                <img [src]="technicianId && technicianValues.picUrl ? technicianValues.picUrl : technicianFormData.pic ? temporarySelectedImg : '../../../assets/icons/user-icon.svg'" alt="profile image">
            </div>
            <div class="add-icon">
                <img *ngIf="!technicianValues.picUrl && !technicianFormData.pic" src="../../../assets/icons/add-icon.svg" alt="add">
                <img *ngIf="technicianValues.picUrl || technicianFormData.pic" src="../../../assets/icons/edit.svg" alt="edit">
                <input type="file" (change)="attachImg($event)" accept=".png, .jpg">
            </div>
        </div>
        <div class="create__form-wrapper">
            <div class="create__form-box">
                <prt-form #formTemplate [fields]="technicianForm" (change)="createTechnicianFormChanges($event)"
                    (isFormValid)="createTechnicianFormISValid($event)" [validateIsRequired]="true"></prt-form>
            </div>
        </div>
    </div>

    <div class="create__body-wrapper create__body-wrapper--block "
        *ngIf="tabSelected !== 'INFORMATION'">
        <div *ngIf="tabSelected === 'CLIENTS'" class="table-external-wrapper table-with-tabs">
            <p-table class="client-table"
                [rows]="clientsTable"
                [columns]="clientsTableColumns"
                [pagination]="clientsTablePagination"
                (changeCurrentPage)="handleClientsChangePage($event)"
                [resultLabel]="'Clientes'"
                [showLoading]="true"
                [isLoading]="isClientFetchingData"
                [isExporting]="isExportingClient"
                (onSorting)="onSortClientsTable($event)"
                [hasSearch]="true"
                [searchForm]="searchForm"
                (onSearchChange)="searchFormChanges($event)"
                [filterActionLabel]="filterLabel"
                [showActionsAtStart]="true"
                [staticRowActions]="true"
                columnMode="force"
                [exportDataConfig]="clientExportDataConfig"
                [enableAutoSearch]="true"
                [topActionsWithLabels]="true"
                [hasTopActions]="true"
                [showPagination]="true"
                [topIcons]="clientsTableIcons"
                (onDownloadAction)="getClientReportData()">
            </p-table>
        </div>

        <div *ngIf="tabSelected === 'QUOTATIONS'" class="table-external-wrapper  table-with-tabs" >
            <p-table
            [rows]="quotationsTable"
            [columns]="quotationsTableColumns"
            [pagination]="quotationsTablePagination"
            (changeCurrentPage)="handleQuotationChangePage($event)"
            [resultLabel]="'Cotizaciones'"
            [showLoading]="true"
            [isLoading]="isQuotationFetchingData"
            [isExporting]="isExportingQuotations"
            (onSorting)="onSortQuotationsTable($event)"
            [hasSearch]="true"
            [searchForm]="searchForm"
            (onSearchChange)="searchFormChanges($event)"
            [filterActionLabel]="filterLabel"
            [showActionsAtStart]="true"
            [staticRowActions]="true"
            columnMode="force"
            [exportDataConfig]="quotationExportDataConfig"
            [topIcons]="quotationsTableIcons"
            [enableAutoSearch]="true"
            [topActionsWithLabels]="true"
            [hasTopActions]="true"
            [additionActionLabel]="'Agregar nueva'"
            (onDownloadAction)="getQuotationReportData()"
            [showPagination]="true">
        </p-table>
        </div>
    </div>


    <div class="create__footer-wrapper" *ngIf="userCanUpdate || !technicianId">
        <prt-button [isInverted]="true" type="solid" [callback]="createTechnician">
            Guardar
        </prt-button>
        <prt-button type="outline" [callback]="cancelCreation">
            Cancelar
        </prt-button>

    </div>
    <div class="create__footer-wrapper" *ngIf="!userCanUpdate && technicianId">
        <prt-button [isInverted]="true" type="solid" [callback]="cancelCreation">
            Aceptar
        </prt-button>
    </div>
</div>
