import { Component, OnDestroy, OnInit } from '@angular/core';
import { labelsStep3, placeholdersStep3, yesOrNotOptions } from './step3.config';
import { PrtOptionDropdownModel, PrtOptionRadioButtonModel } from '@protostech/protos-lib/v2';
import { FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { FormUtilsService } from 'src/app/services/utils/form-utils.service';
import { ProductsService } from 'src/app/services/products/products.service';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss'],
})
export class Step3Component implements OnInit, OnDestroy {
  labels = labelsStep3;
  placeholders = placeholdersStep3;
  yesOrNotOptions: PrtOptionRadioButtonModel[] = yesOrNotOptions;
  airBaseModelIdOptions: PrtOptionDropdownModel[] = [];
  baseTypesOptions: PrtOptionDropdownModel[] = [];
  destroy$ = new Subject<void>();
  loading = false;
  showInputs = {
    airBaseModelId: false,
    airBasesAmount: false,
    needAirBase: false,
    baseTypes: false,
  };

  constructor(
    private formUtilsService: FormUtilsService,
    private productsService: ProductsService,
    public formGroup: FormGroup,
  ) {
    //
  }


  async ngOnInit(): Promise<void> {
    this.loading = true;
    this.subscribeToEvents();
    await this.fillForm();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private async fillForm() {
    const needAirBase = this.formGroup.get('needAirBase');
    if (needAirBase?.value) {
      await this.changeBaseTypes(needAirBase.value);
      await this.changeAirBasesAmount(needAirBase.value);
    } else {
      await this.resetAirBaseModelId();
    }
    this.showInputs.needAirBase = this.airBaseModelIdOptions.length > 0;
    this.updateAirBaseModelIdVisibility(needAirBase?.value);
    this.updateAirBasesAmountVisibility(needAirBase?.value);
    this.updateBaseTypesVisibility(needAirBase?.value);
  }

  private subscribeToEvents() {
    this.onNeedAirBaseChange();
    this.onBaseTypesChange();
  }

  private onBaseTypesChange() {
    const baseTypes = this.formGroup.get('baseTypes');
    baseTypes?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async value => {
      const needAirBaseValue = this.formGroup.get('needAirBase')?.value;
      this.allowAirBaseModelId(value);
      if (!value || value === '') {
        this.resetAirBaseModelId();
      }
      await this.fillairBaseModelIdOptions(value);
      this.updateAirBaseModelIdVisibility(needAirBaseValue);
      this.updateAirBasesAmountVisibility(needAirBaseValue);
    });
  }

  private allowAirBaseModelId(baseType: string) {
    const airBaseModelId = this.formGroup.get('airBaseModelId');
    const allow = !(!baseType || baseType === '');
    this.formUtilsService.toggleControl(airBaseModelId, allow);
  }

  private onNeedAirBaseChange() {
    const needAirBase = this.formGroup.get('needAirBase');
    needAirBase?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(async value => {
      await this.changeBaseTypes(value);
      this.changeAirBasesAmount(value);
      this.updateAirBaseModelIdVisibility(value);
      this.updateAirBasesAmountVisibility(value);
      this.updateBaseTypesVisibility(value);
      if (value === 'false') {
        this.resetAirBaseModelId();
      }
    });
  }

  private async changeBaseTypes(value: string) {
    if (value === 'true') {
      await this.fillTypeOptions();
    } else {
      const baseTypes = this.formGroup.get('baseTypes');
      baseTypes?.reset();
      this.baseTypesOptions = [];
    }
  }

  private resetAirBaseModelId() {
    const airBaseModelId = this.formGroup.get('airBaseModelId');
    airBaseModelId?.reset();
    this.airBaseModelIdOptions = [];
  }

  private changeAirBasesAmount(value: string) {
    const airBasesAmount = this.formGroup.get('airBasesAmount');
    if (value === 'true') {
      if (!airBasesAmount?.value || airBasesAmount?.value === '') {
        airBasesAmount?.setValue(4);
      }
    } else {
      airBasesAmount?.reset();
    }
  }

  private updateAirBaseModelIdVisibility(needAirBaseValue: string) {
    this.showInputs.airBaseModelId = needAirBaseValue === 'true';
    this.formUtilsService.updateValidators(this.formGroup.get('airBaseModelId'), this.showInputs.airBaseModelId);
  }

  private updateAirBasesAmountVisibility(needAirBaseValue: string) {
    this.showInputs.airBasesAmount = needAirBaseValue === 'true';
    this.formUtilsService.updateValidators(this.formGroup.get('airBasesAmount'), this.showInputs.airBasesAmount);
    if (this.showInputs.airBasesAmount) {
      this.formGroup.get('airBasesAmount')?.addValidators(Validators.min(1));
      this.formGroup.get('airBasesAmount')?.updateValueAndValidity();
    }
  }

  private updateBaseTypesVisibility(needAirBaseValue: string) {
    this.showInputs.baseTypes = needAirBaseValue === 'true';
    this.formUtilsService.updateValidators(this.formGroup.get('baseTypes'), this.showInputs.baseTypes);
  }

  async fillairBaseModelIdOptions(baseType: string) {
    const response = await this.productsService.getairBaseModelIds(baseType);
    this.airBaseModelIdOptions = response.map((option: any) => ({ label: option.name, value: option.id }));
    this.formUtilsService.autoSelectSingleOption(this.formGroup.get('airBaseModelId'), this.airBaseModelIdOptions);
  }

  async fillTypeOptions() {
    const response = await this.productsService.getAirConditionerBaseTypes();
    this.baseTypesOptions = response.map((type: any) => ({ label: type, value: type }));
    this.formUtilsService.autoSelectSingleOption(this.formGroup.get('baseTypes'), this.baseTypesOptions);
  }
}
