export enum QuotationStatus {
  CREATED = 'CREATED',
  ISSUED = 'ISSUED',
  ACCEPTED = 'ACCEPTED',
  PAID = 'PAID',
  CANCELED = 'CANCELED',
}

export const quotationStatusTranslations: { [key in QuotationStatus]: string } = {
  [QuotationStatus.CREATED]: 'Borrador',
  [QuotationStatus.ISSUED]: 'Emitida',
  [QuotationStatus.ACCEPTED]: 'Aceptada',
  [QuotationStatus.PAID]: 'Pagada',
  [QuotationStatus.CANCELED]: 'Anulada',
};
