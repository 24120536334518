<div class="create__content-wrapper no-padding">
    <div class="create__header-wrapper" style="margin-top: 20px;">
        <div class="header-wrapper center-align">
            <img *ngIf="isMobile" class="logo" src="../../../assets/icons/quotations-icon.svg" alt="" srcset="">
            <h1 class="text-primary">
                <span *ngIf="!isMobile">Cotización </span> <span *ngIf="quotation">#{{ quotation.numberStr }}</span>
            </h1>
        </div>
        <div class="status">
            <prt-form-field *ngIf="quotation && quotation.status === stauts.ISSUED" size="xl">
                <prt-dropdown prtHostControl [formControl]="statusType"
                    [options]="statusTypeOptions"></prt-dropdown>
            </prt-form-field>
            <h3 *ngIf="quotation && quotation.status !== stauts.ISSUED">
                {{ statusTranslation[quotation.status] }}
            </h3>
        </div>
    </div>
    <div class="create__body-wrapper">
        <iframe *ngIf="pdfUrl && !showNotAllowMessage" [src]="pdfUrl" title="PDF Viewer"></iframe>
        <div *ngIf="showNotAllowMessage">PDF no disponible</div>
    </div>
    <div class="create__footer-wrapper">
        <p-button *ngIf="!isMobile" variant="outline" theme="primary" [callback]="cancel"> Volver </p-button>
        <p-button variant="solid" theme="primary" [callback]="showDuplicateQuotationModal"> 
            <img src="../../../assets/icons/duplicate.svg" style="width: 22px; margin-right: 10px;">
            Duplicar 
        </p-button>
        <p-button  variant="solid" theme="secondary" [callback]="showShareQuotationModal">
            <img src="../../../assets/icons/share-2.svg" style="width: 22px; margin-right: 10px;">
            Compartir
        </p-button>
    </div>
</div>

<!-- MODALS -->

<prt-modal [displayButton]="false" size="sm" [isActive]="showCloneModal" (onAction)="onCloneModalConfirmation($event)"
    [hasCloseButton]="false">
    <div class="modal-wrapper">
        <h2 style="text-align: center" class="text-primary">Confirmación</h2>
        <span class="paragraph-1">¿Estás seguro que deseas duplicar esta cotización?</span>
        <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
            <prt-button type="outline" [callback]="cancelClone">Cancelar</prt-button>
            <prt-button [isInverted]="true" type="solid" [callback]="duplicate">Duplicar</prt-button>
        </div>
    </div>
</prt-modal>

<prt-modal [displayButton]="false" size="sm" [isActive]="showCancelModal" (onAction)="onCancelModalConfirmation($event)"
    [hasCloseButton]="false">
    <div class="modal-wrapper">
        <h2 style="text-align: center" class="text-primary">Confirmación</h2>
        <span class="paragraph-1">¿Estás seguro que deseas cancelar esta cotización?</span>
        <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
            <prt-button type="outline" [callback]="closeCancelQuotation">Cerrar</prt-button>
            <prt-button [isInverted]="true" type="solid" [callback]="cancelQuotation">Aceptar</prt-button>
        </div>
    </div>
</prt-modal>

<prt-modal [displayButton]="false" size="sm" [isActive]="showMarkAsPaidModal"
    (onAction)="onMarkAsPaidModalConfirmation($event)" [hasCloseButton]="false">
    <div class="modal-wrapper">
        <h2 style="text-align: center" class="text-primary">Confirmación</h2>
        <span class="paragraph-1">¿Estás seguro que deseas marcar como pagada esta cotización?</span>
        <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
            <prt-button type="outline" [callback]="closeMarkAsPaidQuotation">Cerrar</prt-button>
            <prt-button [isInverted]="true" type="solid" [callback]="markAsPaid">Aceptar</prt-button>
        </div>
    </div>
</prt-modal>

<prt-modal [displayButton]="false" size="md" [isActive]="showShareModal" (onAction)="onShareModalConfirmation($event)"
    [hasCloseButton]="true">
    <div class="modal-wrapper">
        <h2 style="text-align: center" class="text-primary">Compartir Cotización</h2>
        <div class="modal-body">
            <div class="link-de-pago">
                <div class="title"><span>Link de pago</span></div>
                <div class="option">
                    <span (click)="shareLink()" class="copiar-link">Copiar</span> 
                    <img (click)="shareLink()" src="../../../assets/icons/link.svg" style="width: 22px;">
                </div>
            </div>
            <div *ngIf="!showNotAllowMessage" class="pdf-cotizacion"> 
                <div class="title"><span>PDF de la Cotización</span></div>
                <div class="option">
                    <span (click)="share()" class="compartir-pdf">Compartir</span> 
                    <img (click)="share()" src="../../../assets/icons/share-1.svg" style="width: 20px;">
                </div>
                <div class="option">
                    <span (click)="downloadPdf()" class="compartir-pdf">Descargar</span> 
                    <img (click)="downloadPdf()" src="../../../assets/icons/download-icon.svg" style="width: 20px;">
                </div>
            </div>
            <div class="pdf-cotizacion">
                
            </div>
        </div>
    </div>
</prt-modal>
<app-loading [show]="loadingPdf"></app-loading>