import { Validators } from '@angular/forms';
import { FilterDryerOrGlassType, FilterDryerOrGlassTypeTranslations } from 'src/app/enums/filter-dryer-or-glass-type';

export const labelsStep5 = {
  needPropane: '¿Necesita Propano?',
  needGlassAndFilterDryer: '¿Necesita Visor de Líquido y Filtro Secador?',
  needThermostat: '¿Necesita Termostato?',
  needWeldingRods: '¿Necesita Varillas de Soldar?',
  weldingRodsAmount: 'Cantidad de Varillas de Soldar',
  filterDryerOrGlassType: 'Tipo de Filtro Secador o Visor de Líquido',
};

export const placeholdersStep5 = {
  weldingRodsAmount: 'Cantidad de Varillas de Soldar',
  filterDryerOrGlassType: 'Seleccione tipo',
};

export const step5Controls = {
  needPropane: ['false'],
  needGlassAndFilterDryer: ['false'],
  needThermostat: ['false'],
  needWeldingRods: ['false'],
  weldingRodsAmount: [2],
  filterDryerOrGlassType: [null],
};

export const yesOrNotOptions = [
  { value: 'true', label: 'Si' },
  { value: 'false', label: 'No' },
];

export const filterDryerOrGlassTypeOptions = [
  {
    value: FilterDryerOrGlassType.WELDABLE,
    label: FilterDryerOrGlassTypeTranslations[FilterDryerOrGlassType.WELDABLE],
  },
  {
    value: FilterDryerOrGlassType.THREADABLE,
    label: FilterDryerOrGlassTypeTranslations[FilterDryerOrGlassType.THREADABLE],
  },
];
