<h3>Elementos para la Instalación</h3>
<form *ngIf="formGroup" [formGroup]="formGroup">
    <prt-form-group>
        <prt-form-field size="lg">
            <prt-label>
                <span>{{ labels.needAirBase }}</span>
            </prt-label>
            <prt-radio-button formControlName="needAirBase" [options]="yesOrNotOptions"
                prtHostControl></prt-radio-button>
        </prt-form-field>
        <prt-form-field *ngIf="showInputs.baseTypes" size="lg">
          <prt-label>
              <span>{{ labels.baseTypes }}</span>
          </prt-label>
          <prt-dropdown formControlName="baseTypes" [options]="baseTypesOptions" [placeholder]="placeholders.baseTypes" prtHostControl></prt-dropdown>
        </prt-form-field>
        <prt-form-field *ngIf="showInputs.airBasesAmount" size="lg">
            <prt-label>
                <span>{{ labels.airBasesAmount }}</span>
            </prt-label>
            <prt-input type="number" prtHostControl formControlName="airBasesAmount" [placeholder]="placeholders.airBasesAmount"></prt-input>
        </prt-form-field>
        <prt-form-field *ngIf="showInputs.airBaseModelId" size="lg">
            <prt-label>
                <span>{{ labels.airBaseModelId }}</span>
            </prt-label>
            <prt-dropdown formControlName="airBaseModelId" [options]="airBaseModelIdOptions" [placeholder]="placeholders.airBaseModelId" prtHostControl></prt-dropdown>
        </prt-form-field>
        <prt-form-field size="xl">
            <prt-label>
                <span>{{ labels.needLubricant }}</span>
            </prt-label>
            <prt-radio-button formControlName="needLubricant" [options]="yesOrNotOptions"
                prtHostControl></prt-radio-button>
        </prt-form-field>
        <prt-form-field size="xl">
            <prt-label>
                <span>{{ labels.needTape }}</span>
            </prt-label>
            <prt-radio-button formControlName="needTape" [options]="yesOrNotOptions" prtHostControl></prt-radio-button>
        </prt-form-field>
        <prt-form-field size="xl">
            <prt-label>
                <span>{{ labels.needPoFoam }}</span>
            </prt-label>
            <prt-radio-button formControlName="needPoFoam" [options]="yesOrNotOptions"
                prtHostControl></prt-radio-button>
        </prt-form-field>
    </prt-form-group>
</form>
<app-loading class="step-loading" [show]="loading"></app-loading>
