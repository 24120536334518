import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api/api.service';
import { firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services';
import { TechniciansService } from 'src/app/services/technicians/technicians.service';

@Component({
  selector: 'app-ws-floating-button',
  templateUrl: './ws-floating-button.component.html',
  styleUrls: ['./ws-floating-button.component.scss'],
})
export class WsFloatingButtonComponent {
  isTechnician = false;

  constructor(
    private apiService: ApiService,
    private auth: AuthService,
    private technicianService: TechniciansService,
  ) {
    this.userIsTechnician();
  }

  async userIsTechnician() {
    const me = await firstValueFrom(this.auth.getMe());
    this.isTechnician = this.technicianService.isTechnician(me.role.name);
  }

  waInfo: any = {
    data: {
      attributes: {
        NumberPhone: '+50769978773',
        Message: `Hola,%20necesito%20ayuda%20con%20Dorita`,
      },
    },
  };
  clickToChat() {
    const chatWindow = window.open(
      `https://wa.me/${this.waInfo.data.attributes.NumberPhone}?text=${this.waInfo.data.attributes.Message.replace(
        ' ',
        '20%',
      )}`,
      '_blank',
    );

    if (chatWindow) {
      setTimeout(() => {
        chatWindow.close();
      }, 3000);
    }
  }
}
