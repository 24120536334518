import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PrtOptionDropdownModel } from '@protostech/protos-lib/v2';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { Category } from 'src/app/models/categories';
import { Product } from 'src/app/models/products';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { ProductsService } from 'src/app/services/products/products.service';
export interface ProductRow {
  id: string;
  name: string;
  categoryId: string;
}
@Component({
  selector: 'app-categories-table-modal',
  templateUrl: './categories-table-modal.component.html',
  styleUrls: ['./categories-table-modal.component.scss'],
})
export class CategoriesTableModalComponent implements OnInit, OnDestroy {
  destroy$ = new Subject<void>();
  fetchingData = false;
  loadingData = false;
  selectedProduct?: ProductRow;
  rows: any[] = [];
  categoriesOptions: PrtOptionDropdownModel[] = [];
  private lastClickedElement: HTMLElement | null = null;
  filterForm!: FormGroup;
  query: any = {
    sortKey: 'name',
    sortOrder: 'asc',
    page: 1,
    limit: 10,
  };
  pagination = {
    page: 0,
    limit: 10,
    count: 0,
  };
  columns = [
    {
      prop: 'name',
      name: '',
      sortable: false,
    },
  ];
  innerActions: Record<string, unknown> = {
    clickAction: (row: ProductRow) => this.clickAction(row),
  };
  @Input() show = false;
  @Output() onSelectProduct = new EventEmitter<ProductRow>();
  @Output() onModalStatusChange = new EventEmitter<{ isOpen: boolean }>();

  constructor(
    private categoriesServices: CategoriesService,
    private productsService: ProductsService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
  ) {}

  async ngOnInit(): Promise<void> {
    this.buildForm();
    await this.fillCategories();
    await this.filltable();
  }

  ngOnDestroy(): void {
    this.selectedProduct = undefined;
    this.destroy$.next();
    this.destroy$.complete();
  }

  private buildForm() {
    this.filterForm = this.formBuilder.group({
      q: [null],
      categoryId: [null],
    });
    this.onFilterFormChange();
  }

  onFilterFormChange() {
    this.filterForm.valueChanges.pipe(debounceTime(1000), takeUntil(this.destroy$)).subscribe(values => {
      this.pagination.page = 1;
      this.query = {
        ...this.query,
        ...values,
      };
      if (!values.q || values.q === '') {
        delete this.query.q;
      }
      if (!values.q || values.q === '') {
        delete this.query.q;
      }
      this.filltable();
    });
  }

  private clickAction(row: ProductRow) {
    this.selectedProduct = row;
    this.selectProduct();
  }

  private getRow(product: Product): ProductRow {
    return {
      id: product.id,
      name: product.name,
      categoryId: product.categoryId,
    };
  }

  private async filltable() {
    this.query.page = this.pagination.page;
    const response = await this.productsService.getAllProducts(this.query);
    this.rows = response.data.map(row => this.getRow(row));
    this.pagination = { page: response.page, limit: 10, count: response.count };
    this.loadingData = true;
  }

  modalStatusChange = (evt: { isOpen: boolean }) => {
    this.onModalStatusChange.emit(evt);
    this.show = evt.isOpen;
  };

  changePage(page: number) {
    this.pagination.page = page;
    this.filltable();
  }

  closeModal = () => {
    this.show = false;
    this.onModalStatusChange.emit({ isOpen: false });
  };

  onSort(evt: { dir: string; column: string }) {
    this.pagination.page = 1;
    this.query = { ...this.query, sortKey: evt.column, sortOrder: evt.dir };
    this.filltable();
  }

  onSearchChange(evt: any | unknown) {
    this.query = {
      ...this.query,
      page: 1,
      limit: 10,
    };
    if (evt && evt['query'] && evt['query'] !== '') {
      this.query['name'] = evt['query'];
    } else {
      delete this.query['name'];
    }
    this.filltable();
  }

  async fillCategories() {
    const response = await this.categoriesServices.getAllCategories(this.query);
    this.categoriesOptions = response.data.map((category: Category) => ({
      label: category.name,
      value: category.id,
    }));
  }

  selectProduct = () => {
    if (this.selectedProduct) {
      this.show = false;
      this.onSelectProduct.emit(this.selectedProduct);
      this.onModalStatusChange.emit({ isOpen: false });
    } else {
      this.toastr.error('Debe seleccionar una categoría.');
    }
  };

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('non-static-column') || target.classList.contains('p-table__status-wrapper')) {
      if (this.lastClickedElement) {
        this.lastClickedElement.classList.remove('clicked');
      }
      target.classList.add('clicked');
      this.lastClickedElement = target;
    }
  }
}
