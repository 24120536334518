<div class="create__content-wrapper no-padding">
  <div class="create__header-wrapper">
    <div class="header-wrapper center-align">
      <h1 class="text-primary" *ngIf="userCanUpdate || !quotationId">
        {{ !quotationId ? 'Agregar Nueva Cotización' : 'Editar Cotización #' + quotationValues.number }}
      </h1>
      <h1 class="text-primary" *ngIf="!userCanUpdate && quotationId">
        {{ 'Detalle de la cotización #' + quotationValues.number }}
      </h1>
      <div class="spinnerMobile" [ngClass]="{ 'autosaving': isAutoSaving }">
        <div class="loadingspinner"></div>
        <span style="color: #C3C3C3">Guardando</span>
      </div>
    </div>
    <div class="total">
      <h2 *ngIf="quotationTotalPrice !== 0"> <span>Ganancia: </span><span>{{  getTotalProfit() | currency }}</span>  </h2>
      <h2 *ngIf="quotationTotalPrice !== 0"> <span>Total: </span><span>{{  getTotal() | currency }}</span> </h2>
    </div>
    <div class="tab-menu-wrapper">
      <div class="tab-option" *ngFor="let tabOption of tabMenuOptions" [ngClass]="{'selected': tabSelected === tabOption.key}"
      (click)="tabSelected = tabOption.key;onClickTab()">
          <span>{{tabOption.label}}</span>
      </div>
    </div>
  </div>
  <div class="total-responsive">
    <h2 *ngIf="quotationTotalPrice !== 0"> <span>Ganancia: </span><span>{{  getTotalProfit() | currency }}</span>  </h2>
    <h2 *ngIf="quotationTotalPrice !== 0"> <span>Total: </span><span>{{  getTotal() | currency }}</span> </h2>
  </div>
  <div class="create__body-wrapper create__body-wrapper--block">
    <h2 style="margin-top: 25px;" *ngIf="tabSelected === 'clientData'">Datos Generales</h2>
    <div *ngIf="tabSelected === 'clientData'" class="create__form-wrapper">
      <div class="create__form-box">
        <prt-form
          #formTemplate
          [fields]="quotationForm"
          (change)="createQuotationFormChanges($event)"
          (isFormValid)="createQuotationFormISValid($event)"
          [validateIsRequired]="true"
        ></prt-form>
      </div>
    </div>
    <div class="table-external-wrapper table-external-wrapper--no-overflow-y product-table table-with-tabs" *ngIf="tabSelected !== 'clientData'">
      <p-table
        [rows]="productsTable"
        [columns]="tableColumns"
        [innerIcons]="tableIcons"
        [innerActions]="tableActions"
        [topActions]="topActions"
        [topIcons]="topIcons"
        [pagination]="tablePagination"
        (changeCurrentPage)="handleChangePage($event)"
        [resultLabel]="'Productos agregados'"
        [isLoading]="!isFetchingData"
        [showLoading]="loadingTable"
        class="hide-search-bar"
        [showActionsAtStart]="true"
        columnMode="force"
        [staticRowActions]="true"
        [hasTopActions]="true"
        [additionActionLabel]="'Agregar'"
        [additionalActionLabel]="additionalActionLabel"
        [topActionsWithLabels]="true"
        [showPagination]="false" (onSelectAllChange)="onSelectAllChange($event)">
      </p-table>
    </div>
  </div>
  <div class="create__footer-wrapper">
    <div class="spinner" [ngClass]="{ 'autosaving': isAutoSaving }">
      <div class="loadingspinner"></div>
      <span style="color: #C3C3C3">Guardando</span>
    </div>
    <prt-button [isInverted]="true" type="outline" [callback]="cancelCreation"> Volver </prt-button>
    <prt-button [isInverted]="true" type="solid" [callback]="createQuotation"> Emitir </prt-button>
  </div>

  <!-- All Products Form Modal -->
  <prt-modal
    [displayButton]="false"
    size="md"
    [isActive]="showAllProductsModal"
    (onAction)="onAllProductsFormModalStatus($event)"
    [hasCloseButton]="false"
  >
    <div class="modal-wrapper">
      <h2>{{ getCurrentCategoryName }}</h2>
      <div class="create__form-wrapper">
        <div class="create__form-box">
          <prt-form
            #allProductsFormTemplate
            [fields]="allProductsForm"
            (change)="addAllProductsFormChanges($event)"
            (isFormValid)="addProductFormISValid($event)"
            class="form__wrapper--align-flex-end grey-already-selected"
            [validateIsRequired]="true"
            [isLoading]="allProductsIsLoading"
          ></prt-form>
        </div>
      </div>
      <div class="modal-buttons-wrapper">
        <prt-button [isInverted]="true" type="outline" [callback]="cancelAllProductsAddition"> Cancelar </prt-button>
        <prt-button [isInverted]="true" type="solid" [callback]="addAllProducts"> Agregar producto </prt-button>
      </div>
    </div>
  </prt-modal>

  <!-- Product Form Modal -->
  <prt-modal
    [displayButton]="false"
    size="md"
    [isActive]="showProductFormModal"
    (onAction)="onProductFormModalStatus($event)"
    [hasCloseButton]="false"
  >
    <div class="modal-wrapper">
      <h2>{{ getCurrentCategoryName }}</h2>
      <div class="create__form-wrapper">
        <div class="create__form-box">
          <prt-form
            #productFormTemplate
            [fields]="productForm"
            (change)="addProductFormChanges($event)"
            (isFormValid)="addProductFormISValid($event)"
            class="form__wrapper--align-flex-end grey-already-selected"
            [validateIsRequired]="true"
          ></prt-form>
        </div>
      </div>
      <div class="modal-buttons-wrapper">
        <prt-button [isInverted]="true" type="outline" [callback]="cancelProductAddition"> Cancelar </prt-button>
        <prt-button [isInverted]="true" type="solid" [callback]="addProduct">  {{ editAction ? 'Editar producto' : 'Agregar producto' }}  </prt-button>
      </div>
    </div>
  </prt-modal>

  <app-categories-table-modal 
    *ngIf="showCategoriesTableModal" 
    [show]="showCategoriesTableModal" 
    (onSelectProduct)="filterProducts($event)"
    (onModalStatusChange)="onModalStatusChange($event)">
  </app-categories-table-modal>

  <!-- Product Deletion Modal -->
  <prt-modal
    [displayButton]="false"
    size="sm"
    [isActive]="showDeletionModal"
    (onAction)="onDeleteModalStatus($event)"
    [hasCloseButton]="false"
  >
    <div class="modal-wrapper">
      <h2 style="text-align: center" class="text-primary">Confirmación</h2>
      <span class="paragraph-1">¿Estás seguro que deseas eliminar este producto?</span>
      <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
        <prt-button [isInverted]="true" type="outline" [callback]="cancelDeletion"> Cancelar </prt-button>
        <prt-button [isInverted]="true" type="solid" [callback]="confirmDeletion"> Eliminar </prt-button>
      </div>
    </div>
  </prt-modal>
  
  <!-- Product Deletion Modal -->
  <prt-modal
    [displayButton]="false"
    size="sm"
    [isActive]="showDeletionsModal"
    (onAction)="onDeletionsModalStatus($event)"
    [hasCloseButton]="false"
  >
    <div class="modal-wrapper">
      <h2 style="text-align: center" class="text-primary">Confirmación</h2>
      <span class="paragraph-1">¿Estás seguro que deseas eliminar los productos seleccionados?</span>
      <div class="modal-buttons-wrapper modal-buttons-wrapper--absolute">
        <prt-button [isInverted]="true" type="outline" [callback]="cancelDeletions"> Cancelar </prt-button>
        <prt-button [isInverted]="true" type="solid" [callback]="deleteSelectedProducts"> Eliminar </prt-button>
      </div>
    </div>
  </prt-modal>
</div>

<prt-modal class="wizard" [displayButton]="false" size="md" [isActive]="showWizardModal" (onAction)="onWizardModalChange($event)" [hasCloseButton]="true" [disableCloseOnOverlay]="true">
  <div class="modal-wrapper">
    <h2 style="text-align: center" class="text-primary"></h2>
    <app-wizard 
      *ngIf="showWizardModal && stepsFormArray" 
      [stepsComponents]="stepsComponents" 
      [stepsFormArray]="stepsFormArray" 
      [formsLabels]="formsLabels"
      (stepChange)="stepChange($event)" 
      (finish)="onFinish($event)">
    </app-wizard>
  </div>
</prt-modal>
<app-loading [show]="isLoading"></app-loading>
