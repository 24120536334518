import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { An507FormRefInterface, TextBoxField } from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import { Ability } from '@casl/ability';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { CreatedPdfService } from 'src/app/services/pdf/created-pdf.service';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { getError } from 'src/app/models/error';
import { ExportDataConfig } from '@protostech/protos-lib/lib/interfaces/export-data-config';
import { getDefaultExportDataConfig } from 'src/app/models/export-config';
import { AuthService } from 'src/app/services';
import { firstValueFrom } from 'rxjs';
import { TechniciansService } from 'src/app/services/technicians/technicians.service';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  @ViewChild('searchFormTemplate', { static: false }) searchFormId: An507FormRefInterface | undefined;
  @ViewChild('filterFormTemplate', { static: false }) filterFormId: An507FormRefInterface | undefined;

  searchForm: TextBoxField[] = [
    {
      controlType: 'textBox',
      key: 'query',
      type: 'text',
      value: '',
      label: '',
      required: false,
      width: 'lg',
      noMargin: true,
      showInnerIcon: true,
      icon: '../assets/icons/search.svg',
      position: 'center',
      isSingleRow: true,
      placeholder: 'Escriba su búsqueda',
    },
  ];
  searchQuery: any = {
    page: 1,
    limit: 10,
  };

  userCanCreate = false;
  userCanUpdate = false;
  showAcceptButton = false;

  actionsTooltips = {
    deleteAction: 'Eliminar',
  };

  tableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };

  tableActions: Record<string, unknown> = {
    clickAction: (row: Record<string, unknown>) => this.editClient(row['id'] as string),
    deleteAction: (row: Record<string, unknown>) => this.deleteClient(row['id'] as string),
  };

  clientsTable: any[] = [];

  tableColumns = [
    {
      prop: 'number',
      name: 'Cliente',
      maxWidth: 100,
      minWidth: 100,
    },
    {
      prop: 'name',
      name: 'Nombre',
      minWidth: 250,
    },
    {
      prop: 'phone',
      name: 'Teléfono',
      maxWidth: 150,
      minWidth: 150,
    },
    {
      prop: 'address',
      name: 'Dirección',
      maxWidth: 230,
      minWidth: 230,
    },
    {
      prop: 'purchasesCount',
      name: 'Compras',
      columnAlign: 'center',
      maxWidth: 180,
      minWidth: 180,
    },
    {
      prop: 'User',
      name: 'Técnico',
      sortable: true,
    },
  ];

  tablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  clientId: string | null = null;

  showDeletionModal = false;
  isFetchingData = true;
  isExporting = false;
  filterLabel: any;

  exportDataConfig: ExportDataConfig | undefined;
  isClient = false;

  topActions = {
    additionAction: () => this.addClient(),
  };

  topIcons = {
    additionIcon: '../../../assets/icons/add-icon.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };
  isTechnician = false;
  technicianId!: string;

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private ability: Ability,
    private pdfSvc: CreatedPdfService,
    private clientService: ClientsService,
    private authService: AuthService,
    private technicianService: TechniciansService,
  ) {
    if (ability.can('delete', 'Client')) {
      this.tableColumns.push({
        prop: 'delete',
        name: '',
        sortable: false,
      });
    }
  }

  async ngOnInit() {
    try {
      const me = await firstValueFrom(this.authService.getMe());
      this.isTechnician = this.technicianService.isTechnician(me.role.name);
      if (this.technicianService.isTechnician(me.role.name)) {
        //TO DO Verificar cuando es tecnico y cuando no
        this.technicianId = me.id;
        if (this.technicianId) {
          this.searchQuery = { ...this.searchQuery, userId: this.technicianId };
        }
      }

      this.route.queryParamMap.subscribe(async params => {
        let sortBy = params.get('sortBy');
        const sortOrder = params.get('sortOrder');
        const page = Number(params.get('page'));

        if (sortBy === 'User') {
          sortBy = sortBy + '.name';
        } else if (sortBy === 'number') {
          sortBy = sortBy + 'Str';
        }
        this.buildQuery(sortBy, sortOrder, page);
        if (page) {
          this.tablePagination['page'] = page;
        }
        this.fillTable(sortBy, sortOrder);
      });

      this.canCreate();
      //this.tableActions['downloadAction'] = this.handleCreateReport;
      if (!this.isTechnician) {
        this.setExportationDataConfig();
      }
    } catch (error) {
      console.error(error);
      this.router.navigate(['/login']);
    }
  }

  ngAfterViewInit() {
    this.searchForm[0].triggerOnEnter = this.searchOnEnter;
    this.searchForm[0].triggerOnBlur = this.searchOnBlur;
  }

  //Search
  buildQuery(sortKey?: string | null, sortOrder?: string | null, page?: number) {
    if (page) {
      Object.assign(this.searchQuery, page);
    }
    if (sortKey) {
      Object.assign(this.searchQuery, { sortKey, sortOrder });
    } else {
      Object.assign(this.searchQuery, { sortKey: 'name', sortOrder: 'asc' });
    }
  }

  searchOnEnter = (evt: any) => {
    if (evt.key === 'Enter') {
      const value = evt.target.value;
      this.updateSearchForm(value);
    }
  };

  searchOnBlur = (evt: any) => {
    this.updateSearchForm(evt.target.value);
  };

  updateSearchForm = (value: string) => {
    this.searchFormId?.form.setValue({ [this.searchForm[0].key]: value }, { emitEvent: true });
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsDirty();
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsTouched();
    this.search();
  };

  search = async () => {
    this.isFetchingData = true;
    if (!this.searchQuery['q']) {
      delete this.searchQuery['q'];
    }
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const clients = await this.clientService.getAllClients(this.searchQuery);

        this.clientsTable = clients.data.map(client => {
          return {
            id: client.id,
            number: client.numberStr,
            name: client.name,
            phone: client.phone ?? '-',
            address: client.address ?? '-',
            purchasesCount: client.purchasesCount,
            User: client.User.name,
          };
        });

        this.tablePagination = { page: clients.page, limit: clients.limit, count: clients.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  };

  async searchFormChanges(evt: { query: string } | unknown) {
    if ((evt as { query: string }).query) {
      this.searchQuery = {
        ...this.searchQuery,
        ['q']: (evt as { query: string }).query,
      };
    } else {
      this.resetSearchQuery();
    }
    this.search();
  }

  resetSearchQuery() {
    delete this.searchQuery['q'];
    this.searchQuery = {
      ...this.searchQuery,
      page: 1,
      limit: 10,
    };
    if (this.technicianId) {
      Object.assign(this.searchQuery, { userId: this.technicianId });
    }
  }

  //CRUD
  editClient(clientId: string) {
    this.router.navigate(['/private/clients/edit'], { queryParams: { clientId: clientId } });
  }

  addClient = () => {
    this.router.navigate(['/private/clients/new']);
  };

  deleteClient(clientId: string) {
    this.clientId = clientId;
    this.showDeletionModal = true;
  }

  cancelDeletion = () => {
    this.clientId = null;
    this.showDeletionModal = false;
  };

  confirmDeletion = () => {
    if (this.clientId && this.clientId !== '') {
      this.clientService
        .deleteClient(this.clientId)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Cliente eliminado');
          this.fillTable();
          this.showDeletionModal = false;
          this.clientId = null;
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    }
  };

  onDeleteModalStatus(evt: { isOpen: boolean }) {
    this.showDeletionModal = evt.isOpen;
  }

  // Error Messages

  checkDuplicateErrorMessage(title: string, message: string) {
    const errorMessage = getError(message);
    const duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
    message = errorMessage;
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  //Table
  async fillTable(sortKey?: string | null, sortOrder?: string | null) {
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const clients = await this.clientService.getAllClients({
          page: this.tablePagination.page,
          limit: this.tablePagination.limit,
          sortKey: sortKey ? sortKey : 'name',
          sortOrder: sortOrder ? sortOrder : 'asc',
        });

        this.clientsTable = clients.data.map(client => {
          return {
            id: client.id,
            number: client.numberStr,
            name: client.name,
            phone: client.phone ?? '-',
            address: client.address ?? '-',
            purchasesCount: client.purchasesCount,
            User: client.User.name,
          };
        });

        this.tablePagination = { page: clients.page, limit: clients.limit, count: clients.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  }

  handleChangePage(page: number) {
    this.tablePagination.page = page;
    this.router.navigate(['/private/clients'], { queryParams: { page }, queryParamsHandling: 'merge' });
    if (this.searchQuery['q']) {
      this.search();
    }
  }

  onSortTable(evt: { dir: string; column: string }) {
    this.tablePagination.page = 1;
    this.router
      .navigate(['/private/clients'], {
        queryParams: { sortBy: evt.column, sortOrder: evt.dir, page: 1 },
        queryParamsHandling: 'merge',
      })
      .then(() => this.setExportationDataConfig());
  }

  //Permission
  canCreate() {
    this.userCanCreate = this.ability.can('create', 'Client');
  }

  canUpdate() {
    if (this.ability.cannot('update', 'Client')) {
      this.userCanUpdate = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdate = true;
      this.showAcceptButton = false;
    }
  }

  //Report
  async setExportationDataConfig() {
    this.exportDataConfig = getDefaultExportDataConfig();
    this.exportDataConfig.data = await this.getReportData();
    this.exportDataConfig.fileName = 'Clientes';
    this.exportDataConfig.config.columns = this.getReportColumns();
    this.exportDataConfig.config.pdfConfigOptions = { pdfColumnWidth: [] };
    this.exportDataConfig.config.pdfConfigOptions.pdfColumnWidth = [40, 150, 80, 200, 60, 150];
    this.exportDataConfig.config.pdfConfigOptions.pdfHeaderFontSize = 10;
    this.exportDataConfig.config.pageOritation = 'landscape';
  }

  async getReportData() {
    this.searchQuery['limit'] = 0;
    const clients = await this.clientService.getAllClients(this.searchQuery);
    const clientReport = clients.data.map(client => {
      return {
        number: client.numberStr,
        name: client.name,
        phone: client.phone ?? '-',
        address: client.address ?? '-',
        purchasesCount: `${client.purchasesCount}`,
        User: client.User.name,
      };
    });

    return clientReport;
  }

  private getReportColumns() {
    const columns = this.tableColumns
      .map(col => {
        const reportCol = {
          header: col.name,
          key: col.prop,
          width: 20,
          style: {
            alignment: {
              horizontal: 'left' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          },
        };
        if (reportCol.key === 'purchasesCount') {
          const propertyValue = {
            alignment: {
              horizontal: 'center' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          };
          Object.defineProperty(reportCol, 'style', {
            value: propertyValue,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        return reportCol;
      })
      .filter(col => col.key !== 'delete');
    return columns;
  }
}
