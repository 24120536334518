import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { PrtOptionDropdownModel } from '@protostech/protos-lib/v2';

@Injectable({
  providedIn: 'root',
})
export class FormUtilsService {
  constructor() {
    //
  }

  /**
   * Updates the validators for a given form control based on the required flag.
   *
   * @param control - The form control to update.
   * @param required - A boolean flag indicating whether the control should be required.
   */
  updateValidators(control: any, required: boolean) {
    if (required) {
      control?.setValidators([Validators.required]);
    } else {
      control?.clearValidators();
    }
    control?.updateValueAndValidity();
  }

  /**
   * Toggles the state of a form control between enabled and disabled.
   *
   * @param control - The form control to be toggled.
   * @param enable - A boolean indicating whether to enable (true) or disable (false) the control.
   */
  toggleControl(control: any, enable: boolean, emitEvent = false) {
    if (control) {
      if (enable) {
        control.enable({ emitEvent });
      } else {
        control.disable({ emitEvent });
      }
    }
  }

  /**
   * Automatically selects the single available option for a form control if certain conditions are met.
   *
   * @param control - The form control to update. It should have a `value` property and a `setValue` method.
   * @param options - An array of dropdown options. Each option should have a `value` property.
   * @param emitEvent - A boolean indicating whether to emit an event when the value is set. Defaults to `false`.
   *
   * If the `options` array contains exactly one item and the current value of the control is either `null`, `undefined`, or an empty string,
   * this method sets the control's value to the value of the single option.
   */
  autoSelectSingleOption(control: any, options: PrtOptionDropdownModel[], emitEvent = false) {
    const value = control?.value;
    if (options.length === 1 && (!value || value === '')) {
      control?.setValue(options[0].value, { emitEvent });
    }
  }

  /**
   * Assigns the first option from a list of dropdown options to a form control.
   *
   * @param control - The form control to update. It should have a `setValue` method.
   * @param options - An array of dropdown options. Each option should have a `value` property.
   * @param emitEvent - A boolean indicating whether to emit an event when the value is set. Defaults to `false`.
   */
  assignFirstOption(control: any, options: PrtOptionDropdownModel[], emitEvent = false) {
    const value = control?.value;
    if (options.length > 1 && (!value || value === '')) {
      control?.setValue(options[0].value, { emitEvent });
    }
  }
}
