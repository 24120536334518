import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { An507FormRefInterface, TextBoxField } from '@protostech/protos-lib';
import { ToastrService } from 'ngx-toastr';
import { Ability } from '@casl/ability';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { CreatedPdfService } from 'src/app/services/pdf/created-pdf.service';
import { TechniciansService } from 'src/app/services/technicians/technicians.service';
import { getError } from 'src/app/models/error';
import { ExportDataConfig } from '@protostech/protos-lib/lib/interfaces/export-data-config';
import { getDefaultExportDataConfig } from 'src/app/models/export-config';
import { AuthService } from 'src/app/services';
import { firstValueFrom } from 'rxjs';
import { Technician } from 'src/app/models/technician';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-technicians',
  templateUrl: './technicians.component.html',
  styleUrls: ['./technicians.component.scss'],
})
export class TechniciansComponent implements OnInit {
  @ViewChild('searchFormTemplate', { static: false }) searchFormId: An507FormRefInterface | undefined;
  @ViewChild('filterFormTemplate', { static: false }) filterFormId: An507FormRefInterface | undefined;

  searchForm: TextBoxField[] = [
    {
      controlType: 'textBox',
      key: 'query',
      type: 'text',
      value: '',
      label: '',
      required: false,
      width: 'lg',
      noMargin: true,
      showInnerIcon: true,
      icon: '../assets/icons/search.svg',
      position: 'center',
      isSingleRow: true,
      placeholder: 'Escriba su búsqueda',
    },
  ];
  searchQuery: any = {
    page: 1,
    limit: 10,
  };

  userCanCreate = false;
  userCanUpdate = false;
  showAcceptButton = false;

  tableIcons = {
    modifyIcon: '../assets/icons/edit.svg',
    deleteIcon: '../assets/icons/delete.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };

  tableActions: Record<string, unknown> = {
    clickAction: (row: Record<string, unknown>) => this.editTechnician(row['id'] as string),
    deleteAction: (row: Record<string, unknown>) => this.deleteTechnician(row['id'] as string),
  };

  techniciansTable: any[] = [];

  tableColumns = [
    {
      prop: 'name',
      name: 'Nombre',
      minWidth: 200,
    },
    {
      prop: 'code',
      name: 'Código',
      columnAlign: 'center',
    },
    {
      prop: 'quotationsCount',
      name: 'Cotizaciones',
      columnAlign: 'center',
    },
    {
      prop: 'salesCount',
      name: 'Ventas',
      columnAlign: 'center',
      sortable: true,
    },
  ];

  tablePagination = {
    page: 1,
    limit: 10,
    count: 0,
  };

  technicianId: string | null = null;

  showDeletionModal = false;
  isFetchingData = true;
  isExporting = false;
  filterLabel: any;

  actionsTooltips = {
    deleteAction: 'Eliminar',
  };

  exportDataConfig: ExportDataConfig | undefined;
  isTechnician = false;

  topActions = {
    additionAction: () => this.addTechnician(),
  };

  topIcons = {
    additionIcon: '../../../assets/icons/add-icon.svg',
    downloadIcon: '../assets/icons/download-icon.svg',
  };

  constructor(
    private toastr: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private ability: Ability,
    private pdfSvc: CreatedPdfService,
    private technicianService: TechniciansService,
    private authService: AuthService,
  ) {
    if (ability.can('delete', 'Technician')) {
      this.tableColumns.push({
        prop: 'delete',
        name: '',
        sortable: false,
        columnAlign: 'center',
      });
    }
  }

  async ngOnInit() {
    const me = await firstValueFrom(this.authService.getMe());
    this.isTechnician = this.technicianService.isTechnician(me.role.name);
    this.fillTable();

    this.route.queryParamMap.subscribe(async params => {
      const sortBy = params.get('sortBy');
      const sortOrder = params.get('sortOrder');
      const page = Number(params.get('page'));

      this.buildQuery(sortBy, sortOrder, page);
      if (page) {
        this.tablePagination['page'] = page;
      }

      this.fillTable(sortBy, sortOrder);
    });

    this.canCreate();
    if (!this.isTechnician) {
      this.setExportationDataConfig();
    }
  }

  ngAfterViewInit() {
    this.searchForm[0].triggerOnEnter = this.searchOnEnter;
    this.searchForm[0].triggerOnBlur = this.searchOnBlur;
  }

  //Search
  buildQuery(sortKey?: string | null, sortOrder?: string | null, page?: number) {
    if (page) {
      Object.assign(this.searchQuery, page);
    }
    if (sortKey) {
      Object.assign(this.searchQuery, { sortKey, sortOrder });
    } else {
      Object.assign(this.searchQuery, { sortKey: 'name', sortOrder: 'asc' });
    }
  }

  searchOnEnter = (evt: any) => {
    if (evt.key === 'Enter') {
      const value = evt.target.value;
      this.updateSearchForm(value);
    }
  };

  searchOnBlur = (evt: any) => {
    this.updateSearchForm(evt.target.value);
  };

  updateSearchForm = (value: string) => {
    this.searchFormId?.form.setValue({ [this.searchForm[0].key]: value }, { emitEvent: true });
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsDirty();
    this.searchFormId?.form.controls[this.searchForm[0].key].markAsTouched();
    this.search();
  };

  search = async () => {
    this.isFetchingData = true;
    if (!this.searchQuery['q']) {
      delete this.searchQuery['q'];
    }
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const technicians = await this.technicianService.getAllTechnicians(this.searchQuery);

        this.techniciansTable = technicians.data.map(technician => this.getRow(technician));

        this.tablePagination = { page: technicians.page, limit: technicians.limit, count: technicians.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  };

  async searchFormChanges(evt: { query: string } | unknown) {
    if ((evt as { query: string }).query) {
      this.searchQuery = {
        ...this.searchQuery,
        ['q']: (evt as { query: string }).query,
      };
    } else {
      this.resetSearchQuery();
    }
    this.search();
  }

  resetSearchQuery() {
    delete this.searchQuery['q'];
    this.searchQuery = {
      ...this.searchQuery,
      page: 1,
      limit: 10,
    };
  }

  //CRUD
  editTechnician(technicianId: string) {
    this.router.navigate(['/private/technicians/edit'], { queryParams: { technicianId: technicianId } });
  }

  addTechnician = () => {
    this.router.navigate(['/private/technicians/new']);
  };

  deleteTechnician(technicianId: string) {
    this.technicianId = technicianId;
    this.showDeletionModal = true;
  }

  cancelDeletion = () => {
    this.technicianId = null;
    this.showDeletionModal = false;
  };

  confirmDeletion = () => {
    if (this.technicianId && this.technicianId !== '') {
      this.technicianService
        .deleteTechnician(this.technicianId)
        .then(res => {
          this.checkDuplicateSuccessMessage('', 'Técnico eliminado');
          this.fillTable();
          this.showDeletionModal = false;
          this.technicianId = null;
        })
        .catch(err => {
          console.error(err);
          if (err.error.code) {
            this.checkDuplicateErrorMessage('Error', err.error.code);
          } else {
            this.checkDuplicateErrorMessage('Error', err.error.message);
          }
        });
    }
  };

  onDeleteModalStatus(evt: { isOpen: boolean }) {
    this.showDeletionModal = evt.isOpen;
  }

  // Error Messages

  checkDuplicateErrorMessage(title: string, message: string) {
    const errorMessage = getError(message);
    const duplicate = this.toastr.findDuplicate(title, errorMessage, true, false);
    message = errorMessage;
    if (!duplicate) {
      this.toastr.error(message, title);
    }
  }

  checkDuplicateSuccessMessage(title: string, message: string) {
    const duplicate = this.toastr.findDuplicate(title, message, true, false);
    if (!duplicate) {
      this.toastr.success(message, title);
    }
  }

  //Table
  async fillTable(sortKey?: string | null, sortOrder?: string | null) {
    this.isFetchingData = true;
    return new Promise(async (res, rej) => {
      try {
        const technicians = await this.technicianService.getAllTechnicians({
          page: this.tablePagination.page,
          limit: this.tablePagination.limit,
          sortKey: sortKey ? sortKey : 'name',
          sortOrder: sortOrder ? sortOrder : 'asc',
        });

        this.techniciansTable = technicians.data.map(technician => this.getRow(technician));

        this.tablePagination = { page: technicians.page, limit: technicians.limit, count: technicians.count };
        res(true);
      } catch (error) {
        rej(error);
      }
    }).finally(() => {
      this.isFetchingData = false;
    });
  }

  private getRow(technician: Technician, forReport = false) {
    const row: any = {
      id: technician.id,
      name: technician.name,
      code: technician.code,
      quotationsCount: `${technician.quotationsCount ?? '0'}`,
      salesCount: `${technician.salesCount ?? '0'}`,
    };
    if (forReport) {
      delete row.id;
    }
    return row;
  }

  handleChangePage(page: number) {
    this.tablePagination.page = page;
    this.router.navigate(['/private/technicians'], { queryParams: { page }, queryParamsHandling: 'merge' });
    if (this.searchQuery['q']) {
      this.search();
    }
  }

  onSortTable(evt: { dir: string; column: string }) {
    this.tablePagination.page = 1;
    this.router
      .navigate(['/private/technicians'], {
        queryParams: { sortBy: evt.column, sortOrder: evt.dir, page: 1 },
        queryParamsHandling: 'merge',
      })
      .then(() => this.setExportationDataConfig());
  }

  //Permission
  canCreate() {
    this.userCanCreate = this.ability.can('create', 'Technician');
  }

  canUpdate() {
    if (this.ability.cannot('update', 'Technician')) {
      this.userCanUpdate = false;
      this.showAcceptButton = true;
    } else {
      this.userCanUpdate = true;
      this.showAcceptButton = false;
    }
  }

  //Report
  async setExportationDataConfig() {
    this.exportDataConfig = getDefaultExportDataConfig();
    this.exportDataConfig.fileName = 'Técnicos';
    this.exportDataConfig.data = await this.getReportData();
    this.exportDataConfig.config.columns = this.getReportColumns();
    this.exportDataConfig.config.pdfConfigOptions = { pdfColumnWidth: [] };
    this.exportDataConfig.config.pdfConfigOptions.pdfColumnWidth = [200, 100, 100, 100];
    this.exportDataConfig.config.pdfConfigOptions.pdfHeaderFontSize = 10;
    this.exportDataConfig.config.pageOritation = 'portrait';
  }

  async getReportData() {
    this.searchQuery['limit'] = 0;
    const technicians = await this.technicianService.getAllTechnicians(this.searchQuery);
    const technicianReport = technicians.data.map(technician => this.getRow(technician, true));
    return technicianReport;
  }

  private getReportColumns() {
    const columns = this.tableColumns
      .map(col => {
        const reportCol = {
          header: col.name,
          key: col.prop,
          width: 20,
          style: {
            alignment: {
              horizontal: 'left' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          },
        };
        if (reportCol.key === 'code' || reportCol.key === 'quotationsCount' || reportCol.key === 'salesCount') {
          const propertyValue = {
            alignment: {
              horizontal: 'center' as
                | 'center'
                | 'right'
                | 'fill'
                | 'left'
                | 'justify'
                | 'centerContinuous'
                | 'distributed'
                | undefined,
            },
          };
          Object.defineProperty(reportCol, 'style', {
            value: propertyValue,
            writable: true,
            enumerable: true,
            configurable: true,
          });
        }
        return reportCol;
      })
      .filter(col => col.key !== 'delete');
    return columns;
  }
}
