import { Component, OnInit } from '@angular/core';
import { filterDryerOrGlassTypeOptions, labelsStep5, placeholdersStep5, yesOrNotOptions } from './step5.config';
import { FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { FormUtilsService } from 'src/app/services/utils/form-utils.service';
import { PrtOptionDropdownModel } from '@protostech/protos-lib/v2';

@Component({
  selector: 'app-step5',
  templateUrl: './step5.component.html',
  styleUrls: ['./step5.component.scss'],
})
export class Step5Component implements OnInit {
  labels = labelsStep5;
  yesOrNotOptions = yesOrNotOptions;
  filterDryerOrGlassTypeOptions: PrtOptionDropdownModel[] = filterDryerOrGlassTypeOptions;
  placeholders = placeholdersStep5;
  loading = false;
  destroy$ = new Subject<void>();
  showInputs = {
    weldingRodsAmount: false,
    filterDryerOrGlassType: false,
  };

  constructor(private formUtilsService: FormUtilsService, public formGroup: FormGroup) {
    //
  }

  ngOnInit(): void {
    this.loading = true;
    this.subscribeToEvent();
    this.fillForm();
    this.loading = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private fillForm() {
    const needWeldingRods = this.formGroup.get('needWeldingRods');
    const needGlassAndFilterDryer = this.formGroup.get('needGlassAndFilterDryer');
    if (needWeldingRods?.value) {
      this.changeWeldingRodsAmount(needWeldingRods.value);
    }
    if (needGlassAndFilterDryer?.value) {
      this.showFilterDryerOrGlassType(needGlassAndFilterDryer.value);
      this.changeFilterDryerOrGlassType(needGlassAndFilterDryer.value);
    }
  }

  private subscribeToEvent() {
    this.onNeedWeldingRodsChange();
    this.onNeedGlassAndFilterDryerChange();
  }

  private onNeedGlassAndFilterDryerChange() {
    const needGlassAndFilterDryer = this.formGroup.get('needGlassAndFilterDryer');
    needGlassAndFilterDryer?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.changeFilterDryerOrGlassType(value);
      this.showFilterDryerOrGlassType(value);
    });
  }

  private changeFilterDryerOrGlassType(value: string) {
    if (!this.showInputs.filterDryerOrGlassType) {
      this.formGroup.get('filterDryerOrGlassType')?.reset(null);
    }
  }

  private showFilterDryerOrGlassType(value: string) {
    this.showInputs.filterDryerOrGlassType = value === 'true';
    this.formUtilsService.updateValidators(
      this.formGroup.get('filterDryerOrGlassType'),
      this.showInputs.filterDryerOrGlassType,
    );
  }

  private onNeedWeldingRodsChange() {
    const needWeldingRods = this.formGroup.get('needWeldingRods');
    needWeldingRods?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
      this.changeWeldingRodsAmount(value);
    });
  }

  private changeWeldingRodsAmount(value: string) {
    this.showInputs.weldingRodsAmount = value === 'true';
    const weldingRodsAmount = this.formGroup.get('weldingRodsAmount');
    this.formUtilsService.updateValidators(weldingRodsAmount, this.showInputs.weldingRodsAmount);
    if (this.showInputs.weldingRodsAmount) {
      weldingRodsAmount?.addValidators(Validators.min(1));
      weldingRodsAmount?.updateValueAndValidity();
    }
  }
}
