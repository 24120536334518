import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { WizardGeneralEventsService } from 'src/app/services/wizard-general-events/wizard-general-events.service';
import { WizardService } from 'src/app/services/wizard/wizard.service';

export interface FinishEvent {
  formIndex: number;
  formValues: any;
}

export interface FirstFormGroupWithErrors {
  formIndex: number;
  controlsWithErrors: string[];
}

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnInit, AfterViewInit, OnDestroy {
  maxSteps = 0;
  currentStep = 0;
  isMobile = window.innerWidth <= 768;
  destroy$ = new Subject();
  @Input() stepsFormArray!: FormArray;
  @Input() stepsComponents: any[] = [];
  @Input() formsLabels: any[] = [];
  @Output() next = new EventEmitter<any>();
  @Output() previous = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();
  @Output() finish = new EventEmitter<FinishEvent | FirstFormGroupWithErrors>();
  @Output() stepChange = new EventEmitter<number>();
  @ViewChild('container', { read: ViewContainerRef }) container!: ViewContainerRef;

  constructor(
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private toastr: ToastrService,
    private wizardService: WizardService,
    private wizardGeneralEventsService: WizardGeneralEventsService,
  ) {
    //
  }

  ngOnInit(): void {
    this.maxSteps = this.stepsComponents.length;
  }

  ngOnDestroy(): void {
    this.stepsFormArray.reset();
    this.wizardService.destroy();
    this.wizardGeneralEventsService.destroy();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.createComponent(this.stepsComponents[this.currentStep]));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentStep']) {
      this.stepChange.emit(this.currentStep);
    }
  }

  get currentFormGroup(): FormGroup {
    return this.stepsFormArray?.at(this.currentStep) as FormGroup;
  }

  private createComponent(componentType: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    this.container.createComponent(componentFactory, undefined, this.createInjector(this.currentFormGroup));
  }

  /**
   * Creates a new Injector instance with the provided FormGroup.
   *
   * @param {FormGroup} currentFormGroup - The FormGroup to be provided by the new Injector.
   * @returns {Injector} A new Injector instance with the provided FormGroup.
   */
  private createInjector(currentFormGroup: FormGroup): Injector {
    return Injector.create({
      providers: [
        { provide: FormGroup, useValue: currentFormGroup },
        { provide: FormArray, useValue: this.stepsFormArray },
      ],
      parent: this.injector,
    });
  }

  @HostListener('document:keydown.enter', ['$event'])
  onNext = () => {
    if (this.currentStep < this.maxSteps - 1) {
      this.container.clear();
      this.currentStep++;
      this.createComponent(this.stepsComponents[this.currentStep]);
    }
    this.next.emit();
  };

  onPrevious = () => {
    if (this.currentStep > 0) {
      this.container.clear();
      this.currentStep--;
      this.createComponent(this.stepsComponents[this.currentStep]);
    }
    this.previous.emit();
  };

  onClose = () => {
    this.close.emit();
  };

  onFinish = () => {
    if (this.stepsFormArray.valid) {
      this.finish.emit({ formIndex: this.currentStep, formValues: this.stepsFormArray.value });
    } else {
      const firstFormGroupWithErrors = this.getFirstFormGroupWithErrors();
      if (firstFormGroupWithErrors) {
        const { formIndex } = firstFormGroupWithErrors;
        this.goToForm(formIndex);
        this.highlightInvalidFields(firstFormGroupWithErrors);
        this.finish.emit(firstFormGroupWithErrors);
      }
    }
  };

  private highlightInvalidFields(firstFormGroupWithErrors: FirstFormGroupWithErrors) {
    const { formIndex, controlsWithErrors } = firstFormGroupWithErrors;
    this.stepsFormArray.at(formIndex).markAllAsTouched();
    controlsWithErrors.forEach(controlName => {
      this.stepsFormArray.at(formIndex).get(controlName)?.markAsTouched();
    });
    this.showErrorLabels(firstFormGroupWithErrors);
  }

  private goToForm(index: number) {
    this.currentStep = index;
    this.container.clear();
    this.createComponent(this.stepsComponents[this.currentStep]);
  }

  showNextButton() {
    return this.currentStep !== this.stepsFormArray.length - 1;
  }

  showPreviousButton() {
    return this.currentStep !== 0;
  }

  showFinishButton() {
    return this.currentStep > 1;
  }

  getFirstFormGroupWithErrors(): FirstFormGroupWithErrors | null {
    for (let i = 0; i < this.stepsFormArray.length; i++) {
      const formGroup = this.stepsFormArray.at(i) as FormGroup;
      if (formGroup.invalid) {
        const controlsWithErrors = Object.keys(formGroup.controls).filter(
          controlName => formGroup.controls[controlName].invalid,
        );
        return { formIndex: i, controlsWithErrors };
      }
    }
    return null;
  }

  private showErrorLabels(firstFormGroupWithErrors: FirstFormGroupWithErrors) {
    const { formIndex, controlsWithErrors } = firstFormGroupWithErrors;
    const labels = this.formsLabels[formIndex] || {};
    const errorMessages: string[] = [];
    controlsWithErrors.forEach(controlName => {
      const control = this.stepsFormArray.at(formIndex).get(controlName);
      if (control && control.errors) {
        const label = labels[controlName] || controlName;
        errorMessages.push(label);
      }
    });
    this.toastr.error(errorMessages.join(', '), 'Por favor, complete los campos obligatorios:');
  }
}
